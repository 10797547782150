/**
 * This is the home page
 * @returns {JSX.Element}
 * @constructor
 */
import Experience from "../experience/Experience";
import ModalGallery from "../experience/components/html/modal/types/ModalGallery";
import {useEffect, useRef} from "react";

function PageExperience() {
    const gref = useRef()

    useEffect(()=> {
        setTimeout(()=> {
            gref.current.remove()
        }, 1000)
    }, [])

    return (
        <div className="page">
            <Experience />
            <div style={{display: "none"}} ref={gref} >
                <ModalGallery galleryID={"86969"} actions={null} />
            </div>
        </div>
    )
}

export default PageExperience
