import create from "zustand";
import {findAllByKey, getValueByKey} from "../../utils/utilities";
import {doActions} from "../actionBuilderProcessing";

const [useCollectableStore, useCollectableStoreApi] = create((set, get) => ({
    allCollectables: [],
    currentCollectable: {},
    complete: false,
    completeModal: null,
    actions: {
        setAllCollectables(collectables) {
            set(state => ({ allCollectables: processCollectables(collectables) }))
        },
        getCollectableByID(id) {
            return getValueByKey(get().allCollectables, "id", id)
        },
        collect_item(id, iconIndex) {
            const collectable = this.getCollectableByID(id).collectable

            set(state => ({ completeModal: collectable.collectionCompleteModal }))
            if (collectable.count === collectable.total) {
                return
            }

            collectable.increment()
            if (collectable.count === collectable.total) {
                set(state => ({ complete: true}))
            }

            // update collectedIcons
            collectable.collectedIcons[iconIndex-1] = true

            console.log(collectable.collectedIcons)

            // triggers events in ModalCollectable
            set(state => ({ currentCollectable: {
                    collectable: collectable,
                    cb: Date.now()
                } }))

            /**
             * Events
             */
            // collect
            if (collectable.events && collectable.events.EVENT_COLLECT) {
                doActions(collectable.events.EVENT_COLLECT.actions)
            }

            // index
            collectable.indexevents.forEach( (event, i) => {
                if (event.index === collectable.count) {
                    // console.log('', collectable);
                    if (collectable.indexevents[i].actions) {
                        doActions(collectable.indexevents[i].actions)
                    }
                }
            })

            // complete
            if (collectable.count === collectable.total) {
                if (collectable.events && collectable.events.EVENT_COMPLETE) {
                    setTimeout(()=> {
                        doActions(collectable.events.EVENT_COMPLETE.actions)
                    }, 4000)
                }
            }
        }
    }
}))

function processCollectables(collectables) {
    let processedCollectables = []
    collectables.forEach(collectable => {
        let processedCollectable = {}
        processedCollectable.id = collectable.id
        let assetOn = findAllByKey(collectable, 'assetOn')[0];
        let assetOff = findAllByKey(collectable, 'assetOff')[0];
        processedCollectable.collectable = new Collectable(
            collectable.collectTotal,
            collectable.collectableDescription,
            collectable.events.length ? processEvents(collectable.events) : null,
            collectable.collectableIndex.length ? processIndexEvents(collectable.collectableIndex) : null,
            assetOn ? assetOn : null,
            assetOff ? assetOff : null,
            collectable.collectableCopy,
            collectable.collectionCompleteModal[0]?.id,
            // collectable.collectableIcons?.map(icon => icon.iconOn[0].collectableIcon)
            collectable.collectableIcons?.map(icon => {
                const container = {}
                container.on = icon.iconOn[0].url
                container.off = icon.iconOff[0].url
                return container;
            })
        )

        processedCollectables.push(processedCollectable)
    })
    return processedCollectables
}

export default useCollectableStore
export {useCollectableStoreApi}

class Collectable {
    constructor(total, description, events, indexevents, assetOn, assetOff, copy, collectionCompleteModal, icons) {
        this.count = 0
        this.total = total
        this.description = description
        this.events = events
        this.indexevents = indexevents
        this.assetOn = assetOn
        this.assetOff = assetOff
        this.copy = copy
        this.collectionCompleteModal = collectionCompleteModal ?? null
        this.icons = icons ?? []
        // create blank array of icons for use in selecting asset and position in collectable modal
        this.collectedIcons = Array.from({ length: icons.length }, (_, i) => false)
    }
    increment() {
        this.count++
    }
    decrement() {
        this.count--
    }
}

function processEvents(events) {
    let processedEvents = {}
    events.forEach(event => {
        switch (event.event) {
            case "onCollected":
                // console.log('', event);
                processedEvents.EVENT_COLLECT =  event.eventActions[0]
                break
            case "onComplete":
                // console.log('', event);
                processedEvents.EVENT_COMPLETE =  event.eventActions[0]
                break
            default:
        }
    })
    return processedEvents
}

function processIndexEvents(events) {
    let processedEvents = []
    events.forEach(event => {
        let indexEvent = {}
        indexEvent.index = event.index
        indexEvent.actions = event.indexActions[0].actions
        indexEvent.copy = event.indexActions[0].indexCopy
        processedEvents.push(indexEvent)
    })
    return processedEvents
}


