import {buildQuery, getQueries} from "../graphql/queries";

export const loadQueries = async (query) => {
    let path;
    if (window.location.href.indexOf('localhost') !== -1) {
        path = process.env.REACT_APP_API_LOCAL;
    } else {
        path = process.env.REACT_APP_CRAFT_API;
    }

    const url = `${path}`;
    // console.log(getQueries());
    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: getQueries()
        })
    }).catch((e)=> {
        console.log('e', e);
    });
    const res = await response;

    if (res) {
        return res.json();
    } else {
        console.log('error getting data');
    }

};

export const loadSpecificQuery = async (query) => {
    let path;
    if (window.location.href.indexOf('localhost') !== -1) {
        path = process.env.REACT_APP_API_LOCAL;
    } else {
        path = process.env.REACT_APP_CRAFT_API;
    }

    const url = `${path}`;

    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: buildQuery(query)
        })
    });
    const res = await response.json();
    return res;
};

export const loadJson = async () => {
    let path = "./data.json"
    let response = await fetch(path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }).catch((e)=> {
        console.log('e', e);
    });
    const res = await response;

    if (res) {
        return res.json()
    } else {
        console.log('error getting data');
    }
};
