
import { createRoot } from 'react-dom/client';
import './index.css';
import App from "./App";
import {MemoryRouter} from "react-router-dom";
import LoadSpinner from "./experience/components/html/loadspinner/LoadSpinner";
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MemoryRouter>
    <LoadSpinner/>
    <App />
</MemoryRouter>);
