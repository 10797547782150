import {Sphere} from "../hotspots/Hotspots";
import {useFrame} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import hotspotStore from "../../../../store/hotspots/hotspotStore";


function ParticleCloud({hotspot, count = 40}) {
    const rotateRef = useRef()
    const allHotspots = hotspotStore(state => state.allHotspots)
    const hotspotActions = hotspotStore(state => state.actions)
    const visible = useRef(true)
    useEffect(()=> {
        const hs = hotspotActions.getHotspotByID(hotspot.id)
        if (hs.clicked) {
            visible.current = false
            rotateRef.current.visible = false
        }
    }, [allHotspots])

    useFrame(({clock}) => {
        if (!visible.current) return
        // rotateRef.current.rotation.y = (Math.sin(clock.oldTime / 1000) / 4);
        rotateRef.current.rotation.y += 0.0003;
        rotateRef.current.rotation.y += 0.0003;
        rotateRef.current.rotation.z += 0.0005;
    })

    return (
        <group position={getCoords(hotspot)} scale={1} ref={rotateRef}>
            <Sphere emissive="green" glow="lightgreen"/>
        </group>
    )
}

export default ParticleCloud;

function getCoords(hotspot) {

    if (!hotspot) return [0,0,0]
    return (
        [
            parseFloat(hotspot.hotspotLocation[0].x),
            parseFloat(hotspot.hotspotLocation[0].y),
            parseFloat(hotspot.hotspotLocation[0].z)
        ]
    )
}
