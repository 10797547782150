import ThreeEnvironment from "./components/three/threeEnvironment/ThreeEnvironment";
import UI from "./components/html/ui/UI";
import SceneManager from "./components/three/SceneManager";
import Modal from "./components/html/modal/Modal";
import Menu from "./components/html/menu/Menu";
import {useEffect} from "react";
import actionsStore from "../store/actions/actionStore";
import {modalStoreApi} from "../store/modals/modalStore";

function Experience() {

    useEffect(()=> {
        // modalStoreApi.getState().actions.setOpenModal('87006')
    }, [])

    return (
        <>
            <UI/>
            <Modal />
            <ThreeEnvironment />
            <SceneManager />
            <Menu />
        </>
    )
}

export default Experience
