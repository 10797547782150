import SVG from 'react-inlinesvg';
import {jss} from 'react-jss'
import themeStore from "../../../../../store/themes/themeStore";

function UIIcon({asset, active}) {
     const theme = themeStore(state => state.theme);

     /**
      * This is used to apply theme colours to the SVG icons
      * @type {StyleSheet<"inactive"|"active">}
      */
     const sheet = jss
         .createStyleSheet(
             {
                  active: (data) => ({
                       '& .bg': {
                            fill: theme.colours.highlight
                       },
                       '& path': {
                            fill: theme.colours.secondary
                       },
                       '& text': {
                            stroke: theme.colours.secondary,
                            fill: theme.colours.secondary
                       },
                       '& rect': {
                            stroke: theme.colours.secondary,
                            fill: theme.colours.secondary
                       },
                       '& .active': {
                            //fill: theme.colours.highlight
                       }
                  }),
                  inactive: (data) => ({
                       '& .bg': {
                            fill: theme.colours.primary
                       },
                       '& path': {
                            fill: theme.colours.secondary
                       },
                       '& text': {
                            stroke: theme.colours.secondary,
                            fill: theme.colours.secondary
                       },
                       '& rect': {
                            stroke: theme.colours.secondary,
                            fill: theme.colours.secondary
                       },
                       '& .active': {
                            // fill: theme.colours.secondary
                       }
                  })
             },
             {link: true}
         )
         .attach()

     // this updates the styles with the cms theme
     sheet.update()

     return (
         <div className={active ? sheet.classes.active : sheet.classes.inactive}>
              <SVG src={asset}/>
              {/*<img src={asset} />*/}
         </div>
     )
}

export default UIIcon
