import {memo, useEffect} from "react";
import produce from 'immer'
import create from "zustand";
import {useKeyPress} from "../utils/hooks";
import {getBase64Image, loadImage, loadTexture, LoadVideo} from "../utils/loaders";
import {TextureLoader} from "three";
import globalSetStore from "../store/globalSets/globalSetStore";
import gsap from "gsap";
import {videoStoreApi} from "../store/videos/videoStore";

// https://github.com/pmndrs/zustand/issues/17
const styles = {
    position: "absolute",
    left: "20px",
    bottom: "50px",
    color: "#ffffff",
    fontSize: "18px",
    zIndex: "2",
    // display: "none"
}
const styles2 = {
    position: "absolute",
    left: "20px",
    bottom: "80px",
    color: "#ffffff",
    fontSize: "18px",
    zIndex: "2",
    // display: "none"
}
export const CONNECTION_STATIC = "CONNECTION_STATIC"
export const CONNECTION_SLOW = "CONNECTION_SLOW"
export const CONNECTION_FAST = "CONNECTION_FAST"

export const preloadStore = create((set, get) => ({
    videos: {},
    complete: false,
    landingPagePreload: null,
    _4kSupport: true,
    connectionSpeed: CONNECTION_SLOW,
    actions: {
        setComplete() {
            set(state => ({ complete: true }))
        },
        loadLandingPage(url) {
            // console.log(`Loading landing page image`)
            loadImage(url.url).then((base64image)=> {
                // console.log(`loaded landing`)
                set(state => ({ landingPagePreload: base64image }))
                gsap.to("body", {duration: 1, alpha: 1})
                document.querySelector(".App").style.opacity = 1

                // this.startLoad()

                checkPlaying(set).then((vid)=> {
                    // console.log(vid)
                    // const vid = document.querySelector("#_4ktest")
                    vid.pause()
                    // vid.removeAttribute('src');
                    // vid.src = `${window.location.href}assets/Vault_To_Hub_Transition_1080_5mbps.mp4`
                    vid.src = `https://awards.mccstaging.co.uk/assets/Vault_To_Hub_Transition_1080_5mbps.mp4`
                    vid.load();
                    // vid.srcObject = null;
                    // vid.remove()
                    //

                    this.startLoad()
                })
            })
        },
        addVideo(videoObj) {
            set({videos:
                    {
                        [videoObj.id]: videoObj, ...get().videos
                    }
            })
        },
        setConnectionSpeed(v) {
            set(state => ({ connectionSpeed: v }))
        },
        getBitRate() {

            this.getBitrate().then((bitRate) => {
                console.log(`Bitrate is ${bitRate} Mbps`)
                let symbol

                switch (true) {
                    case (bitRate < 8):
                        symbol = " 🛑 Using static"
                        set(state => ({ connectionSpeed: CONNECTION_STATIC }))
                        break
                    case (bitRate <= 16):
                        symbol = " 🐌 Using low"
                        set(state => ({ connectionSpeed: CONNECTION_SLOW }))
                        break
                    case (bitRate > 16):
                        symbol = " 🚀 Using high"
                        set(state => ({ connectionSpeed: CONNECTION_FAST }))
                        break
                    default:
                }

                document.querySelector(".preloader").textContent = `Bitrate is ${bitRate.toFixed(2)} Mbps ${symbol}`

                const forcedQuality = globalSetStore.getState().globalSets.experience.forceQuality
                if (forcedQuality !== null || forcedQuality !== "default") {
                    switch (forcedQuality[0]) {
                        case "static":
                            set(state => ({ connectionSpeed: CONNECTION_STATIC }))
                            document.querySelector(".preloader").textContent = `Forced quality ${forcedQuality}`

                            /*const vid = document.querySelector("#newvid1")
                            vid.pause()
                            vid.removeAttribute('src')
                            vid.load()
                            vid.src = ''
                            vid.srcObject = null
                            vid.remove()*/
                            break
                        case "high":
                            preloadStore.getState().actions.setConnectionSpeed(CONNECTION_FAST)
                            document.querySelector(".preloader").textContent = `Forced quality ${forcedQuality}`
                            break
                        case "low":
                            preloadStore.getState().actions.setConnectionSpeed(CONNECTION_SLOW)
                            document.querySelector(".preloader").textContent = `Forced quality ${forcedQuality}`
                            break
                        default:
                    }
                }


                preloadStore.getState().actions.setComplete();
            })


            /*Object.entries(get().videos).forEach(([id, video], index) => {
                if (video.preload) {
                    this.addLow(video).then((bitRate) => {

                    })
                }
            })*/
        },
        startLoad() {
            // console.log(`all videos to preload`, get().videos)

            const arr_videos = Object.entries(get().videos)
            /**
             * Filter videos with no static image entry
             */
            let _filtered = arr_videos.filter(video => video[1].static360Image !== undefined );
            let count = 0
            _filtered.forEach(([id, video], index) => {
                // console.log(index, id, video)
                let timeStartStatics, timeEndStatics
                timeStartStatics = Date.now()
                if (video.static360Image) {
                    this.addStatic(video).then(()=> {
                        document.querySelector(".preloader").textContent = `loading static: ${count+1} / ${_filtered.length}`
                        if (count === _filtered.length-1) {
                            timeEndStatics = Date.now()
                            const loadTime = (timeEndStatics-timeStartStatics) / 1000
                            // console.log(`it took ${loadTime}s to load statics`)
                            document.querySelector(".preloader").textContent = `it took ${loadTime}s to load ${_filtered.length} statics`
                            /**
                             * Decide whether to load low quality now to get bitrate
                             */
                            this.getBitRate()
                        }
                        count++
                    })
                }

            });

        },
        addStatic: async (videoObj, final=false)=> {
            // if (!videoObj.preload) return
            if (!videoObj.static360Image) return
            const response = await loadTexture(videoObj.static360Image)
            // console.log(response)
            set(
                produce((state) => {
                    state.videos[videoObj.id].static = {
                        asset: response,
                        loaded: true
                    }
                })
            )
        },
        addLow: async (videoObj)=> {
            if (!get().videos[videoObj.id].preload) return
            // console.log('preloading', get().videos[videoObj.id].lowBitrateMp4)
            document.querySelector(".preloader").textContent = `preloading video`
            const [response, bitRate] = await LoadVideo(get().videos[videoObj.id].lowBitrateMp4)
            // console.log(response)
            set(
                produce((state) => {
                    state.videos[videoObj.id].static = {
                        asset: response,
                        loaded: true
                    }
                })
            )
            return bitRate
        },
        getBitrate: async ()=> {
            // const [response, bitRate] = await LoadVideo(`${window.location.href}assets/TheHubLowMP4_1.mp4`)
            const [response, bitRate] = await LoadVideo(`https://awards.mccstaging.co.uk/assets/Vault_To_Hub_Transition_1080_5mbps.mp4`)
            return bitRate
        }
    }
}))



const Preloader = () => {

    const actions = preloadStore(state => state.actions)
    const videos = preloadStore(state => state.videos)
    const globalSets = globalSetStore(state => state.globalSets)

    useKeyPress("g", ()=> {
        console.log(preloadStore.getState().videos)
    })

/*    useEffect(()=> {
        console.log(`preloader`)
        actions.addVideo("sdf")
    }, [])*/

    useEffect(()=> {
        // console.log(videos)
    }, [videos])



    return (
        <div>
            <div className="preloader" style={styles}>preloader</div>
            <div className="_4ksupport" style={styles2}>Waiting for 4k check</div>
        </div>
    )
};
export default memo(Preloader);



function checkPlaying(set) {
    const vid = document.querySelector("#newvid1")
    // const vid = document.createElement("video");
    vid.crossOrigin = "Anonymous"
    // vid.playsInline = true
    // vid.muted = true
    // vid.loop = true
    // vid.src = `${window.location.href}assets/4ktest.mp4`
    vid.src = `https://awards.mccstaging.co.uk/assets/4ktest.mp4`
    let checked

    return new Promise(resolve => {
        vid.addEventListener("error", (e)=> {
            if (checked) return
            checked = true
            console.log("4k error", e.srcElement.error.code)
            document.querySelector("._4ksupport").textContent = `4K not supported`
            set(state => ({ _4kSupport: false }))
            resolve(vid)
        })
        vid.addEventListener("loadeddata", (e)=> {
            if (checked) return
            checked = true
            document.querySelector("._4ksupport").textContent = `\n4K supported`
            set(state => ({ _4kSupport: true }))
            resolve(vid)
        })
        vid.load()
        // vid.play()

        const _playPromise = vid.play();
        if (_playPromise !== undefined) {
            _playPromise.then(_ => {
                // console.log('playing ok');
            })
                .catch(error => {
                    console.log('4k play error');
                });
        }
    });
}