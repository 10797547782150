import create from "zustand";
import {modalStoreApi} from "../modals/modalStore";
import {sceneStoreApi} from "../scenes/sceneStore";
import {routeStoreAPI} from "../routes/routeStore";
import {boomerangStoreAPI} from "../boomerangs/boomerangStore";
import uiStore, {uiStoreApi} from "../../experience/components/html/ui/uiStore";
import helpStore, {helpStoreApi} from "../help/helpStore";
import {audioStoreApi} from "../audio/audioStore";
import {videoStoreApi} from "../videos/videoStore";
import {useCollectableStoreApi} from "../collectables/collectableStore";
import iconStore, {iconStoreApi} from "../icons/iconsStore";
import {hotspotStoreApi} from "../hotspots/hotspotStore";
import {fullscreenVideoStoreApi} from "../../experience/components/three/FullScreenVideo.js/FullScreenVideo";
import {modalVideoStore} from "../../experience/components/html/modal/types/ModalVideo";
import siteStore, {STATE_PAGE} from "../siteStore";
const SET_VOLUME = "setvolume"
const OPEN_MODAL = "openmodal"
const SET_STATE = "setstate"
const SET_SCENE = "setscene"
const PLAY_ROUTE = "playroute"
const CANCEL_MODAL = "cancelmodal"
const PLAY_BOOMERANG = "playboomerang"
const SET_MAP_ACTIVE = "setmapactive"
const OPEN_STATE_HELP = "openstatehelp"
const OPEN_EXTERNAL = "openexternal"
const PLAY_VIDEO_AUDIO = "playvideoaudio"
const PLAY_AUDIO = "playaudio"
const TOGGLE_STATE_AUDIO = "togglestateaudio"
const STOP_AUDIO = "stopaudio"
const COLLECT_ACHIEVEMENT = "collectachievement"
const TOGGLE_VIDEO_TEXTURE_AUDIO = "togglevideotextureaudio"
const SET_FULLSCREEN_VIDEO_HOTSPOT = "setfullscreenvideohotspot"
const SET_VIDEO_TIME = "setvideotime"
const TOGGLE_MENU_ICON = "toggleMenuIcon"
const TOGGLE_STAMP_ICON = "toggleStampIcon"

const [actionsStore, actionsStoreApi] = create((set, get) => ({
    something: 0,
    actions: {
        doSomething: (v) => set({something: v}),
        processActionArray(actionArray) {
            processActions(actionArray)
        }
    }
}))
export default actionsStore
export {actionsStoreApi}

function processActions(actionArray) {
    actionArray.forEach(action => {
        // console.log('', action.typeHandle);
        switch (action.typeHandle) {
            case SET_VOLUME:
                // console.log('setting volume', action.setVolume);
                audioStoreApi.getState().actions.setGlobalVolume(action.setVolume)
                break;
            case OPEN_MODAL:
                //console.log('', action);
                modalStoreApi.getState().actions.setOpenModal(action.modalID[0].id)
                break;
            case PLAY_ROUTE:
                // console.log('', action);
                routeStoreAPI.getState().actions.playRoute(action.param[0].id)
                modalStoreApi.getState().actions.setCloseModal()
                uiStoreApi.getState().actions.setMenu(false)
                break;
            case PLAY_BOOMERANG:
                // return;
                // console.log('', action);
                boomerangStoreAPI.getState().actions.playBoomerang(action.boomerangID[0].id)
                modalStoreApi.getState().actions.setCloseModal()
                uiStoreApi.getState().actions.setMenu(false)
                break;
            case SET_STATE:
                //console.log('', action);
                sceneStoreApi.getState().actions.setSceneState(action.scene[0].id, action.stateHandle)
                break;
            case CANCEL_MODAL:
                //console.log('', action);
                modalStoreApi.getState().actions.setCloseModal(true)
                // siteStore.getState().actions.setPageState(STATE_PAGE)
                break;
            case SET_MAP_ACTIVE:
                //console.log('', action);
                sceneStoreApi.getState().actions.setMapVisibility(action.scene[0].id, action.active)
                break;
            case OPEN_STATE_HELP:
                //console.log('', action);
                helpStoreApi.getState().actions.getHelp()
                break;
            case OPEN_EXTERNAL:
                //console.log('', action);
                window.open(action.url, "_blank");
                break;
            case PLAY_VIDEO_AUDIO:
                videoStoreApi.getState().actions.playVideoAudio(action.playing)
                break;
            case PLAY_AUDIO:
                // console.log('', action.audioID[0]);
                audioStoreApi.getState().actions.playAudioByID(action.audioID[0].id)
                break;
            case SET_SCENE:
                sceneStoreApi.getState().actions.setScene(action.scene[0].id)
                break;
            case TOGGLE_STATE_AUDIO:
                audioStoreApi.getState().actions.toggleStateAudio(action.enabled)
                break;
            case STOP_AUDIO:
                audioStoreApi.getState().actions.stopAudio(action)
                break;
            case COLLECT_ACHIEVEMENT:
                useCollectableStoreApi.getState().actions.collect_item(action.collection[0].id, action.iconIndex)
                break;
            case TOGGLE_VIDEO_TEXTURE_AUDIO:
                hotspotStoreApi.getState().actions.playHotspotVideoTextureAudio(action.chooseHotspot[0].id, action.videoTextureAudioOnOff)
                break;
            case SET_FULLSCREEN_VIDEO_HOTSPOT:
                fullscreenVideoStoreApi.getState().setOpen(true)
                break;
            case SET_VIDEO_TIME:
                modalVideoStore.getState().setTime(action.time)
                break
            case TOGGLE_MENU_ICON:
                uiStore.getState().actions.toggleMenuIcon(action.showMenu)
                // console.log('toggle menu', action.showMenu)
                break;
            case TOGGLE_STAMP_ICON:
                uiStore.getState().actions.toggleStampIcon(action.showStamp)
                // console.log('toggle stamp', action.showStamp)
                break;
            default:
                console.log('unsupported action');
        }
    })
}
