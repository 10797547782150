import ShaderCircle from "./shadercircle/ShaderCircle";
import {Billboard, Html, Ring, Text} from "@react-three/drei";
import {extend, useFrame, useThree} from '@react-three/fiber'
import {debounce} from "debounce";
import Interweave from "interweave";
import {useRef, memo, useState, useEffect, Suspense} from "react";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import Icon from "./icon/Icon";
import {cameraStore, controlsStore} from "../../controls/CControls";
import {experienceStore} from "../../SceneManager";
import audioStore from "../../../../../store/audio/audioStore";
import globalSetStore, {globalSetStoreAPI} from "../../../../../store/globalSets/globalSetStore";
import {Vector3} from "three";
import videoStore from "../../../../../store/videos/videoStore";
import {starShardStore} from "../../nebula/SparkleNebula";
import {delay} from "../../../../../utils/utilities";
import gsap from "gsap"
import {isMobile} from "react-device-detect";
import iconStore from "../../../../../store/icons/iconsStore";
import sceneStore from "../../../../../store/scenes/sceneStore";
import themeStore from "../../../../../store/themes/themeStore";
import hotspotStore, {hotspotStoreApi} from "../../../../../store/hotspots/hotspotStore";
import DebugBox from "../../../../debug/DebugBox";

/**
 *
 * @param hotspot
 * All we need here is the location xyz
 * https://stackoverflow.com/questions/45927235/change-color-of-an-svg-dynamically-and-apply-as-texture-in-three-js
 */
function IconHotspot({hotspot, index}) {
    const [actionsDisplay, setActionsDisplay] = useState()
    const [hasPos, setHasPos] = useState(false)
    const [hide, setHide] = useState(false)
    const [_scale, setScale] = useState(5)
    const [pointerDown, setPointerDown] = useState(false)
    const [iconActive, setIconActive] = useState(false)
    const setTarget = controlsStore(state => state.setTarget)
    const is2D = experienceStore(state => state.is2D)
    const play2D = videoStore(state => state.play2D)
    const audioActions = audioStore(state => state.actions)
    const hotspotActions = hotspotStore(state => state.actions)
    const currentVolume = audioStore(state => state.currentVolume)
    const sfx = globalSetStore(state => state.sfx)
    const hotspotIndex = sceneStore(state => state.hotspotIndex)
    const hotspotActivate = sceneStore(state => state.hotspotActivate)
    const theme = themeStore(state => state.theme)
    const thisRef = useRef()
    const circRef = useRef()
    const orbitRef = useRef()
    const {camera} = useThree()


    /**
     * Global volume change livestream volume
     */
    useEffect(()=> {
        if (currentVolume) {
            hotspot.channel?.unmute()
        } else {
            hotspot.channel?.mute()
        }
    }, [currentVolume])

    useEffect(()=> {
        // console.log('ON', );
        setIconActive(true)
        hotspot.active = true;

        if (hotspot.clicked) {
            setHide(true)
            setIconActive(false)
            hotspot.active = false;
        }
        return ()=> {
            // console.log('Off', );
            // setIconActive(false)
            // hotspot.active = false;
        }
    }, [])

    useEffect(() => {
        if (!hotspot) return;
        let actions = [];
        hotspot.actions.forEach(action => {
            actions.push(`${Object.values(action)}`)
        })
        setActionsDisplay(hotspot.title)
        // console.log('',hotspot );

        if (!hotspot.icon.hotspotBillboard) {
            // console.log('hotspot.icon.hotspotBillboard', hotspot.icon.hotspotBillboard);
            // thisRef.current.quaternion.copy(camera.quaternion)

            setHasPos(true)
            thisRef.current.lookAt(new Vector3((0, 0, 0)))
        }
    }, [hotspot])

    const [hovered, setHover] = useState(false)
    useEffect(() => {
        if (hide) return
        document.body.style.cursor = hovered ? 'pointer' : 'auto';
        if (hovered) {
            document.body.classList.add('hOver')
            if (hotspot.hotspotEffect === "circle") {
                gsap.to(circRef.current.scale, {duration: 0.3, x: 1.2, y: 1.2, ease: 'expo.out'})
            }

        } else {
            document.body.classList.remove('hOver')
            if (hotspot.hotspotEffect === "circle") {
                gsap.to(circRef.current.scale, {duration: 0.3, x: 1, y: 1, ease: 'power2.in'})
            }
        }
    }, [hovered]);
    useFrame(({clock}) => {
        // rotateRef.current.rotation.y = (Math.sin(clock.oldTime / 1000) / 4);
        // circRef.current.rotation.y += 0.01;
        // circRef.current.rotation.x += 0.01;
    })
    useFrame(({clock}) => {
        // rotateRef.current.rotation.y = (Math.sin(clock.oldTime / 1000) / 4);
        if (hotspot.hotspotEffect === "orbit") {
            orbitRef.current.rotation.y += 0.01;
        }
        // circRef.current.rotation.x += 0.01;
    })

    useEffect(() => {
        if (is2D) {
            setHover(false)
        }
    }, [is2D])

    const onClick = (isKey=false) => {
        if (play2D || hide || !iconStore.getState().canClick) return
        // check if dragged
        sceneStore.getState().actions.setHotspotIndex(index)
        if (!isKey) {
            if (!isMobile) {
                if ((Date.now() - pointerDown) > 500) return;
            }
        }

        /**
         *
         */

        // check hide on click
        if (hotspot.hotspotHideOnClick) {
            /**
             * Disable clicking on other hotspots while animation is playing
             */
            iconStore.getState().actions.setCanClick(false)

            let scaleObj = {
                x: 1.3, y: 1.3, z: 1.3
            }

            gsap.to(scaleObj, {duration: 0.8, x: 0, y: 0, z: 0, ease: "back.in(3)", onUpdate: ()=> {
                    thisRef.current.scale.set(
                        scaleObj.x,
                        scaleObj.y,
                        scaleObj.z
                    )
                }})
            delay(700).then(()=> {
                starShardStore.getState().setPosition([
                        hotspot.hotspotLocation[0].x,
                        hotspot.hotspotLocation[0].y,
                        hotspot.hotspotLocation[0].z
                    ]
                )
            })
            delay(1000).then(()=> {
                setHide(true)
                doActions(hotspot.actions)

                hotspotActions.setClicked(hotspot.id)

                iconStore.getState().actions.setCanClick(true)
            })
        } else {
            doActions(hotspot.actions)
        }
        // sfx
        audioActions.playAudioByID(sfx.sfxClick)
        // move camera
        setTarget(hotspot.hotspotLocation[0])
    }

    useFrame(() => {
        if (hotspot.icon.hotspotBillboard)
            thisRef.current.quaternion.copy(camera.quaternion)
    })

    /**
     * keyboard control
     */

    /*useEffect(()=> {
        if (index === hotspotIndex) {
            setTarget(hotspot.hotspotLocation[0])
        }
    }, [hotspotIndex])*/

    useEffect(()=> {
        if (!hotspotActivate) return
        if (index === hotspotIndex) {
            // console.log('hotspotActivate', hotspotActivate);
            iconStore.getState().actions.setCanClick(true)
            onClick(true)
        }
    }, [hotspotActivate])


    return (
        <group position={getCoords(hotspot)} scale={8} visible={!hide}>
            {/*<DebugBox/>*/}
            {/*<group position={getCoords2(hotspot)} scale={1} visible={!hide}>*/}
            {/*<group position={[0, 0, 0]} scale={1} visible={!hide}>*/}

            {/*<ParticleCloud />*/}

            <group ref={thisRef}
                   onPointerOver={(event) => setHover(true)}
                   onPointerOut={(event) => setHover(false)}
                   onPointerDown={(event) => setPointerDown(Date.now())}
                   onClick={debounce(onClick, 200)}>

                <Suspense fallback={null}>
                    <Icon hotspot={hotspot}/>
                </Suspense>

                {
                    hotspot.hotspotLabel ?


                        <group position={[0,-hotspot.hotspotLabel.position.y/42,1.1]}>

                            {
                                hotspot.hotspot3dText && hotspot.hotspot3dText.map((copy, index) => (
                                    <group position={[0,index*-2,0]} key={index}>
                                        <Text characters="abcdefghijklmnopqrstuvwxyz0123456789!"
                                              fontSize={1.5}
                                              color={'#ffffff'}
                                              maxWidth={48}
                                              lineHeight={1.1}
                                              textAlign={'center'}
                                              font={"assets/Linotype-UniversLTPro-BoldCond.otf"
                                              }
                                        >
                                            {hotspot.hotspot3dText[index]?.copy}
                                        </Text>
                                    </group>
                                ))
                            }

                        </group>
                        /*<Html transform={true} className={`label iconlabel`} style={{pointerEvents: 'none', display: 'block', opacity: 1, whiteSpace: 'nowrap'}}>
                            <div className="hotspotHtml" style={{color: `${hotspot.hotspotLabel.colour}`, fontSize: `${hotspot.hotspotLabel.size}px`, userSelect: "none", transform: `translateX(${hotspot.hotspotLabel.position.x}px) translateY(${hotspot.hotspotLabel.position.y}px)`}}>
                                <Interweave content={hotspot.hotspotLabel.copy}/>
                            </div>
                        </Html>*/

                        : null
                }

                {
                    hotspot.hotspotEffect === "circle" ?
                        <group position={[0, 0, 0]} ref={circRef}>
                            <ShaderCircle spin={false} animated={false} scale={5}
                                          color={'#e84511'}
                                          alpha={0.1}
                                          innerRadius={0.8} outerRadius={0.9} rotation={[0, 0, 0]}/>
                        </group>
                        : null
                }
                {
                    (hotspot.hotspotEffect === "orbit") ?
                        <group position={[0, 0, 0]} ref={orbitRef}>
                            <ShaderCircle spin={false} animated={false} scale={5}
                                          color={'#e84511'}
                                          alpha={0.1}
                                          innerRadius={0.8} outerRadius={0.9} rotation={[0, 0, 0]}/>
                            <group rotation={[0, Math.PI / 2, 0]}>
                                <ShaderCircle spin={false} animated={false} scale={5}
                                              color={'#e84511'}
                                              alpha={0.1}
                                              innerRadius={0.8} outerRadius={0.87} rotation={[0, 0, 0]}/>
                            </group>
                        </group>
                        : null
                }
            </group>

        </group>
    )
}


export default memo(IconHotspot)

function getCoords(hotspot) {
    // console.log('', hotspot.hotspotLocation[0]);
    return (
        [
            parseFloat(hotspot.hotspotLocation[0].x),
            parseFloat(hotspot.hotspotLocation[0].y),
            parseFloat(hotspot.hotspotLocation[0].z)
        ]
    )
}

function getCoords2(hotspot) {
    let v = 0.1
    console.log('', hotspot.hotspotLocation[0]);
    console.log('', parseFloat(hotspot.hotspotLocation[0].x) * v);
    console.log('', parseFloat(hotspot.hotspotLocation[0].y) * v);
    console.log('', parseFloat(hotspot.hotspotLocation[0].z) * v);
    return (
        [
            parseFloat(hotspot.hotspotLocation[0].x) * v ,
            parseFloat(hotspot.hotspotLocation[0].y) * v ,
            parseFloat(hotspot.hotspotLocation[0].z) * v
        ]
    )
}
