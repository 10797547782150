import {memo, useEffect, useRef, useState} from "react";
import create from "zustand";
import {useAnimationFrame, useKeyPress} from "../utils/hooks";
import useGamepadEvents from "@beskar-labs/use-gamepad-events";
import SVG from 'react-inlinesvg';
import gsap from "gsap"
import "./_gamepad.scss"
import ico_xbox from "../assets/xbox-control-for-one-svgrepo-com.svg";

export const gamepadStore = create((set, get) => ({
    gamepadPressed: {
        value: 0,
        cb: 0
    },
    usingKeyOrPad: false,
    type: "pad",
    setGamepadPressed: (v, type="pad") => {
        // console.log(`gampepad setting`, v)

        setTimeout(()=> {
            set({gamepadPressed: {
                    value: v, cb: Date.now()
                }})
        }, 30)
        setTimeout(()=> {
            set({gamepadPressed: {
                    value: null, cb: Date.now()
                }})
        }, 60)

        if (!get().usingKeyOrPad) {
            document.querySelector("body").classList.add("usingPad")
        }
        document.querySelector("body").setAttribute('data-input', type);
        set({usingKeyOrPad: true})
    }
}))

function GamePad() {
    const setGamepadPressed = gamepadStore(state => state.setGamepadPressed)

    const gamepadEvents = useGamepadEvents({
        onConnect: (gamepad) => {
            console.log("Gamepad connected")
            gsap.set(gamepadRef.current, {x: -20})
            gsap.to(gamepadRef.current, {x: 0, duration: 0.3, ease: 'expo.out'})
            gsap.to(gamepadRef.current, {alpha: 1})
            gsap.to(gamepadRef.current, {alpha: 0, duration: 1, delay: 2})
        },
        onDisconnect: () => console.log('Gamepad disconnected'),
        onReady: (gamepad) => console.log(`Gamepad ${gamepad.id}`),
    });

    useKeyPress("Enter", ()=> {
        setGamepadPressed("A", "key")
    })
    useKeyPress("b", ()=> {
        setGamepadPressed("B", "key")
    })
    useKeyPress("Escape", ()=> {
        setGamepadPressed("BACK", "key")
    })
    useKeyPress("m", ()=> {
        setGamepadPressed("Start", "key")
    })
    useKeyPress("ArrowLeft", ()=> {
        setGamepadPressed("LEFT", "key")
    })
    useKeyPress("ArrowRight", ()=> {
        setGamepadPressed("RIGHT", "key")
    })
    useKeyPress("ArrowUp", ()=> {
        setGamepadPressed("UP", "key")
    })
    useKeyPress("ArrowDown", ()=> {
        setGamepadPressed("DOWN", "key")
    })

    gamepadEvents.on('a', () => {
        // console.log('A button pressed');
        setGamepadPressed("A")
    });
    gamepadEvents.on('b', () => {
        // console.log('B button pressed');
        setGamepadPressed("B")
    });
    gamepadEvents.on('x', () => {
        // console.log('X button pressed');
    });
    gamepadEvents.on('y', () => {
        // console.log('Y button pressed');
    });
    gamepadEvents.on('left', () => {
        // console.log('LEFT button pressed');
        setGamepadPressed("LEFT")
    });
    gamepadEvents.on('right', () => {
        // console.log('RIGHT button pressed');
        setGamepadPressed("RIGHT")
    });
    gamepadEvents.on('up', () => {
        // console.log('DOWN button pressed');
        setGamepadPressed("UP")
    });
    gamepadEvents.on('down', () => {
        // console.log('DOWN button pressed');
        setGamepadPressed("DOWN")
    });
    gamepadEvents.on('share', () => {
        // console.log('BACK button pressed');
        setGamepadPressed("BACK")

    });
    gamepadEvents.on('options', () => {
        // console.log('START button pressed');
        setGamepadPressed("Start")
    });

    const gamepadRef = useRef()
    return (
        <div className="gamepad" style={{opacity: 0}} ref={gamepadRef}>
            <div className="gamepad__icon">
                <SVG src={ico_xbox}/>
            </div>
            <div className="gamepad__copy">
                Controller<br/>connected
            </div>
        </div>
    )
}
export default memo(GamePad)