import sceneStore from "../../../../store/scenes/sceneStore";
import {useEffect, useRef} from "react";
import IconHotspot from "./hotspotComponents/IconHotspot";
import {useSceneBlock} from "../helpers/GroupBlock";
import {hotspotStoreApi} from "../../../../store/hotspots/hotspotStore";
import gsap from "gsap";
import videoStore from "../../../../store/videos/videoStore";
import {modalTypeStore} from "../../html/modal/Modal";
import modalStore from "../../../../store/modals/modalStore";
import {useControl} from "../../../../utils/hooks";
import {STATE_MODAL, STATE_PAGE} from "../../../../store/siteStore";
import {Sparkles} from "@react-three/drei";
import ParticleCloud from "../Particles/ParticleCloud";

/**
 * maps hotspots from current Scene
 * @returns THREE.group of IconHotspot components
 */
function Hotspots() {
    const hotspots = sceneStore(state => state.sceneHotspots)
    const currentScene = sceneStore(state => state.currentScene)
    const play2D = videoStore(state => state.play2D)
    const fullScreenModal = modalTypeStore(state => state.fullScreenModal)
    const modalHideHotspots = modalStore(state => state.modalHideHotspots)
    const hotspotsRef = useRef()
    const {fadeOutGroup, fadeInGroup} = useSceneBlock()


    /**
     * Listen for hotspots changed
     * This is now based on scene states
     */
    useEffect(() => {
        if (!hotspots || !hotspots.length) return;
        fadeOutGroup(hotspotsRef.current, 0);
        // gsap.to(".iconlabel", {duration: 0, alpha: 0})

        // eslint-disable-next-line
    }, [hotspots])

    /**
     * Listen for change in scene
     * use this for hiding hotspots
     */
    useEffect(() => {
        if (!currentScene) return
        fadeOutGroup(hotspotsRef.current, 0)
        // gsap.to(".iconlabel", {duration: 0, alpha: 0})
        /**
         * Handle video textures here too
         */
        // console.log('----------------', );

        // eslint-disable-next-line
    }, [currentScene])

    useEffect(()=> {
        hotspotsRef.current.visible = !modalHideHotspots

    }, [modalHideHotspots])
    useEffect(()=> {
        if (!play2D) return
        setTimeout(()=> {
            fadeOutGroup(hotspotsRef.current, 0.5)

        }, 0)

    }, [play2D])

    const videoPlaying = videoStore(state => state.videoPlaying)
    useEffect(()=> {
        if (videoPlaying && !play2D) {
            document.body.classList.add("hideLabels")
            fadeOutGroup(hotspotsRef.current, 0)
            setTimeout(()=> {
                document.body.classList.remove("hideLabels")
                fadeInGroup(hotspotsRef.current, 0.3)
                gsap.to(".iconlabel", {duration: 1, alpha: 1})
            }, 1500)
        } else {
            // fadeOutGroup(hotspotsRef.current, 0)
        }
    }, [videoPlaying])


    function Control() {
        const keyPress = useControl(STATE_PAGE, true)
        useEffect(()=> {
            if (!keyPress) return
            switch (keyPress.key) {
                case "LEFT":
                    sceneStore.getState().actions.setHotspotDirection(false)
                    break;
                case "RIGHT":
                    sceneStore.getState().actions.setHotspotDirection(true)
                    break;
                case "A":
                    sceneStore.getState().actions.setHotspotActivate()
                    break;
                default:
            }
        }, [keyPress])
        return null
    }

    return (
        <group ref={hotspotsRef}>
            <Control />
            {
                hotspots && hotspots.map((hotspot, index) => (

                    <group key={index} >
                        {/*                     <group position={[-398.79418569755893,-23.897872502665834,0.3226932891043942]}>
                            <Sphere />
                        </group>*/}
                        {
                            hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id).hotspotEffect === "sparkles" ?
                                <ParticleCloud hotspot={
                                    hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
                                }/>
                                : null
                        }

                        <IconHotspot index={index} hotspot={
                            hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
                        }/>
                    </group>
                ))
            }
        </group>
    )
}

export default Hotspots

export const Sphere = ({ size = 1, amount = 90, color = 'white', emissive, glow, ...props }) => (
    <mesh {...props} scale={1}>
        <Sparkles count={amount} scale={size * 48} size={18} speed={0.6} noise={0}/>
    </mesh>
)
