import CookieConsent from "react-cookie-consent";
import {useEffect, useRef, useState} from "react";
import siteStore from "../store/siteStore";
import {getCookie} from "../utils/utilities";
import InnerHTML from "dangerously-set-html-content";
import globalSetStore from "../store/globalSets/globalSetStore";
import Interweave from "interweave";
import Tracking, {trackEvent, TrackingLabels} from "../tracking/Tracking";
import {useKeyPress} from "../utils/hooks";

function LucozadeTracking() {

     const [showCookie, setShowCookie] = useState(true)
     const dropCookie = siteStore(state => state.dropCookie)
     const actions = siteStore(state => state.actions)
     const globalSets = globalSetStore(state => state.globalSets)
     const checkCookieRef = useRef()
     const code = "G-X1MFSY5QQ1"
     useKeyPress("c", ()=> {
          deleteCookie('_site_acceptance')
          deleteCookie('_ga')
          deleteCookie(`_ga_${code.slice(2)}`)
     })

/*     useEffect(()=> {
          if (!globalSets) return
          console.log(globalSets.trackingCode.slice(2))

          setTimeout(()=> {
               console.log(`delete`)
               deleteCookie('_site_acceptance')
               deleteCookie('_ga')
               deleteCookie(`_ga_${globalSets.trackingCode.slice(2)}`)
          }, 10000)
f
     }, [globalSets])*/

     useEffect(()=> {

          /*setTimeout(()=> {
               setCookie('_site_acceptance','2',7);
          }, 6000)*/

          function checkCookie() {
               let c = getCookie("_site_acceptance");
               if (!globalSets || !globalSets.trackingCode) return
               // console.log('checking')
               switch(c) {
                    case "1":
                         console.log("user declined cookie")
                         // deleteCookie('_site_acceptance')
                         // deleteCookie('_ga')
                         // deleteCookie(`_ga_${code.slice(2)}`)
                         clearInterval(checkCookieRef.current)
                         break;
                    case "2":
                         console.log("user accepted cookie")
                         actions.setDropCookie(true)
                         clearInterval(checkCookieRef.current)
                         break;
                    default:
               }
          }
          checkCookieRef.current = setInterval(checkCookie, 500)
          return ()=> {
               clearInterval(checkCookieRef.current)
          }
     }, [globalSets])

     return (
         <>
              <Tracking/>
              {
                   dropCookie ?
                       <InnerHTML html={getTrackingCode(globalSets.trackingCode)}/>
                       : null
              }
         </>
     )
}

export default LucozadeTracking

function getTrackingCode(code) {
    console.log('code', code);
     return `
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=${code}"></script>
    <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${code}');
    </script>
`
}


function listenCookieChange(callback, interval = 1000) {
     let lastCookie = document.cookie;
     setInterval(()=> {
          let cookie = document.cookie;
          if (cookie !== lastCookie) {
               try {
                    callback({oldValue: lastCookie, newValue: cookie});
               } finally {
                    lastCookie = cookie;
               }
          }
     }, interval);
}

function setCookie(name,value,days) {
     var expires = "";
     if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
     }
     document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function deleteCookie(name) {
     document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
     // setCookie(name, "", null , null , null, 1);
}