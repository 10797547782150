import {Canvas, useLoader, useThree} from "@react-three/fiber";
import { ResizeObserver } from '@juggle/resize-observer'; // safari polyfill
import * as THREE from 'three'
import {useRef, useState, Suspense, useEffect} from "react";
import {PositionalAudio, Text} from "@react-three/drei"
// import DebugBox from "../../../debug/DebugBox";
import {Lights} from "../lights/Lights";
import Sphere360 from "../sphere360/Sphere360";
import CControls from "../controls/CControls";
import Hotspots from "../hotspots/Hotspots";
import Video2D from "../video2D/Video2D";
import {useKeyPress} from "../../../../utils/hooks";
import HotspotCreator from "./HotspotCreator";
import Postprocessing from "../effects/Postprocessing";
import DebugBox from "../../../debug/DebugBox";
import sound from "../../../../assets/2.mp3"
import FullScreenVideo from "../FullScreenVideo.js/FullScreenVideo";
import TestRender from "../TestRender";
import TransitionPlane from "../TransitionPlane";
import {Stats} from "@react-three/drei"
import Blah from "../nebula/blah";
import StarShardNebula from "../nebula/StarShardNebula";
import SparkleNebula from "../nebula/SparkleNebula";
import Test2D from "../Test2D";


function ThreeEnvironment() {
     const [showHotspots, setShowHotspots] = useState(false);
     useKeyPress("h", () => {
          if (window.isDebug) {
               setShowHotspots(true)
          }
     })
     useKeyPress("j", () => {
          if (window.isDebug) {
               setShowHotspots(false)
          }
     })
     const canvasRef = useRef();


     return (
         <Canvas resize={{polyfill: ResizeObserver}} ref={canvasRef}
                 camera={{far: 2300, position: [0, 0, -10.1], fov: 70 }}
                 gl={{preserveDrawingBuffer: true, alpha: false, antialias: true}}
                 onCreated={({gl}) => {
                      gl.toneMapping = THREE.NoToneMapping
                      gl.outputEncoding = THREE.sRGBEncoding
                 }}>
              {/*<Stats showPanel={0} className="stats" />*/}
              {/*<SparkleNebula />*/}

              <Lights />
              <Sphere360 />
              <Video2D />
                {/*<Test2D/>*/}
              <FullScreenVideo />
              {/*<TransitionPlane />*/}
              <Hotspots />
              {
                   showHotspots ?
                       <HotspotCreator/>
                       : null
              }
              <color attach="background" args={[new THREE.Color("#000000").convertSRGBToLinear()]} />
              {/*<DebugBox />*/}
              <CControls />
              <Postprocessing />
         </Canvas>
     )
}
export default ThreeEnvironment
