// audio store
import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import videoStore, {videoStoreApi} from "../videos/videoStore";
import {sceneStoreApi} from "../scenes/sceneStore";
import {audioStoreApi} from "../audio/audioStore";
import {controlsStoreApi} from "../../experience/components/three/controls/CControls";
import {experienceStoreApi} from "../../experience/components/three/SceneManager";
import siteStore, {STATE_MODAL, STATE_PAGE, STATE_ROUTE} from "../siteStore";
import {CONNECTION_STATIC, preloadStore} from "../../sitecomponents/Preloader";

const [routeStore, routeStoreAPI] = create((set, get) => ({
    destination: 0,
    allRoutes: [],
    cameraOrientation: {x:10,y:0,z:0},
    actions: {
        setAllRoutes(routes) {
            set(state => ({ allRoutes: processRoutes(routes) }))
            // console.log('', get().allRoutes)
        },
        playRoute(id) {
            siteStore.getState().actions.setPageState(STATE_ROUTE)
            let route = getValueByKey(get().allRoutes, "id", id)
            /**
             * Play route
             */

            /**
             * If we're using statics just switch scenes
             */
            if (preloadStore.getState().connectionSpeed === CONNECTION_STATIC) {
                setTimeout(()=> {
                    sceneStoreApi.getState().actions.setScene(route.routeEnd )
                }, 500)
                return
            }


            // set destination
            set(state => ({ destination: route.routeEnd }))

            // set video
            set(state => ({ routeVideo: route.routeVideo }))

            // set end orientation
            set(state => ({ cameraOrientation: route.cameraOrientation }))

            videoStore.getState().actions.playRoute(
                route
            )

            // have to wait for video playing to get duration to send to audio
            getDuration().then(()=> {
                /**
                 * Get audio of routeEnd
                 */
                let routeEnd = sceneStoreApi.getState().actions.getScene(route.routeEnd)

                if (routeEnd.current.currentAudio) {
                    audioStoreApi.getState().actions.playAudioByID(routeEnd.current.currentAudio, true, videoStoreApi.getState().videoElement.duration)
                }
            })

            experienceStoreApi.getState().actions.setIs2d(true)
            document.body.classList.add("is2d")
        },
        onEnd() {
            siteStore.getState().actions.setPageState(STATE_PAGE)
            if (get().cameraOrientation.x.length) {
                // controlsStoreApi.getState().setTarget(get().cameraOrientation, false)
                sceneStoreApi.getState().actions.setScene(get().destination, false)
            } else {
                sceneStoreApi.getState().actions.setScene(get().destination, false)
            }

            experienceStoreApi.getState().actions.setIs2d(false)
            document.body.classList.remove("is2d")
        }
    }
}))
export default routeStore
export {routeStoreAPI}

function processRoutes(routes) {
    let processedRoutes = []
    routes.forEach(route => {
        // console.log('', route);
        let processedRoute = {}
        processedRoute.id = route.id
        processedRoute.title = route.title
        processedRoute.boomerangSkippable = route.boomerangSkippable
        processedRoute.routeEnd = route.routeEnd[0].id
        processedRoute.routeVideo = route.routeVideo[0].id
        processedRoute.cameraOrientation = route.cameraOrientation ? route.cameraOrientation[0] : {x:0,y:0,z:0}
        processedRoutes.push(processedRoute)

        // Add videos to preload
        if (processedRoute.routeVideo) {
            videoStoreApi.getState().actions.addActiveVideo(processedRoute.routeVideo)
        }
    })
    return processedRoutes
}


async function getDuration() {
    let currentVideo = videoStoreApi.getState().videoElement
    while (isNaN(currentVideo.duration)) {
        await new Promise(resolve => {
            requestAnimationFrame(resolve);
        })
    }
}
