/* global gtag */


import {useEffect} from "react";
const TrackingLabels = {
    names: {
        BUTTON_CLICK: "button_click",
        OPEN: "open",
        SHOW: "show",
        NAVIGATE_MENU: "navigate_menu",
        SHARE: "share",
        LOAD: "load",
        VIEW: "view",
        SET_STATE: "set_state",
        PRIMARY_CLICK: "primary_click",
        SECONDARY_CLICK: "secondary_click",
        BUTTON_SKIP: "button_skip",
        PLAY: "play",
        PAUSE: "pause",
        PERCENT_5: "percent_5",
        PERCENT_25: "percent_25",
        PERCENT_50: "percent_50",
        PERCENT_75: "percent_75",
        PERCENT_90: "percent_90",
        COMPLETE: "complete",
        CONTENT: "content",
        OPEN_MODAL: "open_modal",
        OPEN_STATE_HELP: "open_state_help"
    },
    categories: {
        LANDING_PAGE_COOKIES: "landing_page_cookies",
        LANDING_PAGE: "landing_page",
        UI: "ui",
        SOCIAL: "social",
        SCENE: "scene",
        MODAL: "modal",
        ROUTE: "route",
        HOTSPOT: "hotspot",
        BOOMERANG: "boomerang",
        VIDEO: "video",
        GALLERY: "gallery",
        ACTION: "action",
    }
}
function trackEvent(event) {
    // console.log('tracking', event);
    if (window.gtag !== undefined) {
        if (window.isDebug) {
            console.log('tracking', event)
        }
        if (window.location.href.indexOf('localhost') !== -1) {
            // console.log('not tracking local', );
        } else {
            gtag( 'event', event.name, {
                'event_category': event.category,
                'event_label': event.label
            });
        }
    }
}

function Tracking() {
    // useEffect(()=> {
    //     console.log('tracking', );
    // }, [])
    return null
}
export default Tracking
export {trackEvent, TrackingLabels}
