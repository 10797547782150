import "./_menu.scss"
import close from "../../../../assets/icons/svg/close_menu.svg";
import help from "../../../../assets/menu/help.svg";
import map from "../../../../assets/menu/map.svg";
import uiStore from "../ui/uiStore";
import {useControl, useMenuScaling, useScaling, useWindowSize} from "../../../../utils/hooks";
import {memo, useEffect, useRef} from "react";
import globalSetStore from "../../../../store/globalSets/globalSetStore";
import Interweave from "interweave";
import sceneStore, {sceneStoreApi} from "../../../../store/scenes/sceneStore";
import helpStore from "../../../../store/help/helpStore";
import SVG from 'react-inlinesvg';
import gsap from "gsap";
import {debounce} from "debounce";
import {useDebouncedCallback} from "use-debounce";
import modalStore from "../../../../store/modals/modalStore";
import audioStore from "../../../../store/audio/audioStore";
import ico_twitter from "../../../../assets/share/twitter.svg"
import ico_facebook from "../../../../assets/share/logo-facebook.svg"
import ico_instagram from "../../../../assets/share/instagram.svg"
import ico_whatsapp from "../../../../assets/share/whatsapp.svg"
import ico_email from "../../../../assets/share/email.svg"
import ico_link from "../../../../assets/share/link.svg"
import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {trackEvent, TrackingLabels} from "../../../../tracking/Tracking";
import UIIcon from "../ui/components/UIIcon";
import themeStore from "../../../../store/themes/themeStore";
import {STATE_HOME, STATE_MENU} from "../../../../store/siteStore";



const ShareIcons = memo(() => {
    const globalSets = globalSetStore(state => state.globalSets)
    const share_url = globalSets && globalSets.menu.menuShareURL
    function copyClipboard() {
        console.log('globalSets.menu.menuShareURL', globalSets.menu);
        navigator.clipboard.writeText(globalSets.menu.menuShareURL)
    }

    return (
        <div className="menushare">
            {
                globalSets && globalSets.menu.share_twitter ?
                    <TwitterShareButton url={globalSets.menu.menuShareURL}>
                        <SVG src={ico_twitter}/>
                    </TwitterShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_facebook ?
                    <FacebookShareButton url={globalSets.menu.menuShareURL}>
                        <SVG src={ico_facebook}/>
                    </FacebookShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_whatsapp ?
                    <WhatsappShareButton url={globalSets.menu.menuShareURL}>
                        <SVG src={ico_whatsapp}/>
                    </WhatsappShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_email ?
                    <EmailShareButton url={globalSets.menu.menuShareURL}>
                        <SVG src={ico_email}/>
                    </EmailShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_link ?
                    <div onClick={copyClipboard}>
                        <SVG src={ico_link}/>
                    </div>
                    : null
            }
        </div>
    )
})

function Menu() {
    const actions = uiStore(state => state.actions)
    const helpActions = helpStore(state => state.actions)
    const toggleMenu = uiStore(state => state.toggleMenu)
    const globalSets = globalSetStore(state => state.globalSets)
    const scenes = sceneStore(state => state.allScenes)
    const menuScenes = sceneStore(state => state.menuScenes)
    const currentScene = sceneStore(state => state.currentScene)
    const modalActions = modalStore(state => state.actions)
    const audioActions = audioStore(state => state.actions)
    const theme = themeStore(state => state.theme)
    const sfx = globalSetStore(state => state.sfx)
    const menuRef = useRef()
    const menuContainer = useRef()
    const menuContent = useRef()

    function Control() {
        const keyPress = useControl(STATE_MENU)
        const menuIndex = useRef(0)
        useEffect(()=> {
            const buttons = document.querySelectorAll(`.menu__item`)
            if (!buttons.length) return
            buttons.forEach(button => {
                button.classList.remove("menu__item--input")
            })

            document.querySelector(`[data-index='0']`).classList.add("menu__item--input")
        }, [])
        useEffect(()=> {
            if (!keyPress) return
            // console.log(`menu`, keyPress.key)
            let arr_scenes = []
            scenes.forEach(scene => {
                if (scene.sceneMap) {
                    arr_scenes.push(scene)
                }
            })
            switch (keyPress.key) {
                case "A":

                    // console.log('menuIndex.current', menuIndex.current)
                    onMapClick(arr_scenes[menuIndex.current].id)
                    break
                case "DOWN":
                    menuIndex.current += 1
                    if (menuIndex.current >= scenes.length -1) {
                        menuIndex.current = scenes.length - 1
                    }
                    break
                case "UP":
                    menuIndex.current -= 1
                    if (menuIndex.current <= 0) {
                        menuIndex.current = 0
                    }
                    break
                case "BACK":
                    onMenu()
                    break
                default:
            }

            if (arr_scenes && arr_scenes.length) {
                document.querySelectorAll(`.menu__item`).forEach(button => {
                    button.classList.remove("menu__item--input")
                })
                document.querySelector(`[data-index='${menuIndex.current}']`).classList.add("menu__item--input")
            }

        }, [keyPress])
        return null
    }



    useEffect(() => {
        // console.log('toggleMenu', toggleMenu);
        let menu = menuRef.current.getBoundingClientRect().width

       /* if (toggleMenu) {
            gsap.to(menuContainer.current, {duration: 1, x: "100%"})
        } else {
            gsap.to(menuContainer.current, {duration: 1, x: "0%"})
        }*/
        if (toggleMenu) {
            menuContainer.current.style.visibility = "visible"
        }
        gsap.killTweensOf(menuContainer.current)
        gsap.to(menuContainer.current, {duration: 1.1, ease: "expo.out", x: !toggleMenu ? menu : 0,
        onComplete: ()=> {
            if (!toggleMenu) {
                menuContainer.current.style.visibility = "hidden"
            }
        }
        })
    }, [toggleMenu])

    useEffect(() => {
        const scale = () => {
            // console.log('menu', toggleMenu);
        }
        window.addEventListener('resize', scale)
        // scale()
    }, [])

    function onMenu() {
        audioActions.playAudioByID(sfx.sfxClick)
        actions.toggleMenu()
    }

    useMenuScaling(menuRef);

    function onMapClick(sceneID) {
        audioActions.playAudioByID(sfx.sfxClick)
        modalActions.setCloseModal()
        sceneStoreApi.getState().actions.setScene(sceneID)
        actions.toggleMenu()

        // tracking
            trackEvent(
                {
                    name: TrackingLabels.names.NAVIGATE_MENU,
                    category: TrackingLabels.categories.UI,
                    label: `scene_${sceneStoreApi.getState().actions.getScene(sceneID).title}`
                }
            )
        // end tracking
    }

    function getActive(id) {
        if (!currentScene) return
        if (id === currentScene.id) {
            return "menu__item--active"
        } else {
            return ''
        }
    }

    const debouncedHelp = useDebouncedCallback(
        () => {
            audioActions.playAudioByID(sfx.sfxClick)
            helpActions.getHelp()
            actions.toggleMenu()
        },
        2000,
        {leading: true}
    );

    // menu__container
    const menuIndexRef = useRef(0)
    return (
        <>
            <div className={`menu__container`} ref={menuContainer}>
                <div className="menu__close ui__icon" onClick={onMenu}>
                    <SVG src={close}/>
                </div>
                {
                    globalSets && globalSets.menu.menuShowHelp ?
                        <div className="menu__help ui__icon" onClick={debouncedHelp}>
                            <UIIcon asset={theme.icons && theme.icons.help.asset} active={true} position={theme.icons.gyro.iconPosition}/>
                            {/*<SVG src={help}/>*/}
                        </div>
                        : null
                }
                <div className={`menu col-menu`} ref={menuRef}>

                    <div className={`menu__content`} ref={menuContent}>

                            {
                                globalSets && globalSets.menu.menuAbout && globalSets.menu.menuShowAbout ?
                                    <div className="headericon">
                                        <Interweave content={globalSets.menu.menuAbout}/>
                                        {/*<UIIcon asset={theme.icons && theme.icons.help.asset} active={true} position={theme.icons.gyro.iconPosition}/>*/}
                                        {/*<SVG src={help}/>*/}
                                    </div>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuShowMap ?
                                    <>
                                        {
                                            globalSets.menu.menuMapTitle ?
                                                <>
                                                <div className="menu__line"/>
                                                <div className="headericon">
                                                    <Interweave content={globalSets.menu.menuMapTitle}/>
                                                    <SVG src={map}/>
                                                </div>
                                                </>
                                                : null
                                        }

                                        {
                                            menuScenes && menuScenes.map((scene, index) => (
                                                scene.sceneMap ?
                                                    <div key={index}
                                                         data-index={index}
                                                         className={`menu__item ${getActive(scene.id)}`}
                                                         onClick={() => {
                                                             onMapClick(scene.id)
                                                         }}>
                                                        <div className="menu__line menu__line--min"/>
                                                        <h3>{scene.title}</h3>
                                                        <Interweave content={scene.sceneDescription}/>
                                                    </div> : null
                                            ))
                                        }
                                    </>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuShowShare ?
                                    <>
                                        <div className="menu__line"/>
                                        <div className="headericon">
                                            <Interweave content={globalSets.menu.menuShare}/>
                                            <ShareIcons/>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuDisclaimer && globalSets.menu.menuShowDisclaimer ?
                                    <>
                                        <div className="menu__line"/>
                                        <div className="headericon">
                                            <Interweave content={globalSets.menu.menuDisclaimer}/>
                                        </div>
                                    </>
                                    : null
                            }
                    </div>
                </div>
            </div>
            <div className={`menu__cover ${toggleMenu ? "menu__cover--open" : "menu__cover--closed"}`}
                 onClick={onMenu}/>
            <Control/>
        </>
    )
}

export default Menu
