// all videos including 360s and 2ds
// handling HLS etc
import create from "zustand";
import {delay, getValueByKey, searchArray} from "../../utils/utilities";
import {createHLSObject} from "./hlsmanager/HLSManager";
import * as THREE from 'three';
import {sceneStoreApi} from "../scenes/sceneStore";
import {routeStoreAPI} from "../routes/routeStore";
import {boomerangStoreAPI} from "../boomerangs/boomerangStore";
import {globalSetStoreAPI} from "../globalSets/globalSetStore";
import {isDesktop, isIOS, isMobile, isSafari} from "react-device-detect";
import {transitionStoreApi} from "../../experience/components/three/TransitionPlane";
import {RGBAFormat, RGBAIntegerFormat, TextureLoader} from "three";
import {postStore} from "../../experience/components/three/effects/Postprocessing";
import uiStore from "../../experience/components/html/ui/uiStore";
import audioStore from "../audio/audioStore";
import {getBase64Image, loadImage, LoadVideo} from "../../utils/loaders";
import siteStore from "../siteStore";
import {preloadStore, CONNECTION_SLOW, CONNECTION_FAST, CONNECTION_STATIC} from "../../sitecomponents/Preloader";

const VIDEO_TYPE_BOOMERANG = "VIDEO_TYPE_BOOMERANG"
const VIDEO_TYPE_ROUTE = "VIDEO_TYPE_ROUTE"
const VIDEO_TYPE_360 = "VIDEO_TYPE_360"
let checkEnd
let allVideos = []

const [videoStore, videoStoreApi] = create((set, get) => ({
    allVideoData: null,
    activeVideos: [], // this is populated by seeing which videos are used in the site
    allHLSStreams: null,
    videoElement: null,
    videoTexture: null,
    videoTexture2: null,
    videos: [], // trying 2 videos for transitions
    videoIndex: 0, // trying 2 videos for transitions
    currentHLS: null,
    play2D: false,
    videoEnded: null,
    videoType: false,
    videoPlaying: false,
    videoRestart: null,
    videoDuration: 0,
    showSkip: false,
    canSkip: false,
    fragLoaded: 0,
    static360: null,
    actions: {
        /**
         * All video data from GraphQL
         * @param videos
         */
        setAllVideos(videos) {
            const allVideoData = processVideos(videos);
            set(state => ({allVideoData: allVideoData}))

            const hlsStreams = createHLSStreams(allVideoData)
            set(state => ({allHLSStreams: hlsStreams}))

            get().actions.createVideos(false)
        },
        addActiveVideo(v) {
            const s = get().activeVideos.indexOf(v)
            if (s === -1) {
                set(state => ({activeVideos: [...get().activeVideos, v]}))
            }
            // console.log('', get().activeVideos);
        },
        /**
         * @param id
         * @returns json from graphQL
         */
        getVideoByID(id) {
            return getValueByKey(get().allVideoData, "id", id)
        },
        /**
         * Returns object containing hls object
         * @param id
         */
        getHLSObjectByVideoId(id) {
            return get().allHLSStreams.find(x => x.id === id)
        },
        /**
         * This is the video that gets used for three.js textures
         * @param attachToDom for debugging
         */
        createVideos(attachToDom) {
            let vids = []
            for (let i=0; i<1; i++) {
                const {vid, texture} = this.getVideo(false, i)
                vids[i] = {
                    vid: vid, texture: texture
                }
            }

            set(state => ({videoElement: vids[0].vid}))
            set(state => ({videoTexture: vids[0].texture}))
            // set(state => ({videoTexture2: vids[1].texture}))
            set(state => ({videos: vids}))
            // console.log('', vids);
            allVideos = vids
            checkEndOfVideos()
        },
        getVideo(attachToDom, id) {
            // const vid = document.createElement("video");
            // console.log('', `newvid${id+1}`);
            const vid = document.getElementById(`newvid${id+1}`)
            // console.log('', `newvid${id+1}`);
            // vid.width = 1920
            // vid.height = 1080
            // vid.dataset.id = id
            vid.classList.add(`previewVideo${id}`)
           /* vid.crossOrigin = "Anonymous"
            vid.playsInline = true
            vid.muted = true
            vid.loop = false*/
            // vid.setAttribute("muted", "true")
            // vid.setAttribute("crossOrigin", "Anonymous")
            // vid.setAttribute("playsInline", "true")
            // vid.setAttribute("loop", "false")
            let texture = new THREE.VideoTexture(vid)
            // texture.minFilter = THREE.LinearFilter
            // texture.magFilter = THREE.LinearFilter
            // texture.format = THREE.sRGBEncoding
            document.querySelector(".vidcont").appendChild(vid)
            if (attachToDom) {
                // document.querySelector(".vidcont").appendChild(vid)
            }
            /*vid.addEventListener("ended", ()=> {
                onEnd(get, set)
            })*/
            return {vid, texture}
        },
        getEdgeCastStream(audioEnabled) {
            const vid = document.getElementById("myVideoId");
            vid.crossOrigin = "Anonymous";
            vid.playsInline = true;
            vid.muted = !audioEnabled;
            let texture = new THREE.VideoTexture(vid);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            // texture.format = THREE.RGBFormat;
            playPromise(vid)
            return {texture: texture, videoElement: vid}
        },
        getHotspotVideoTexture(src, audioEnabled) {
            const vid = document.createElement("video");
            vid.crossOrigin = "Anonymous";
            vid.playsInline = true;
            vid.muted = !audioEnabled;
            vid.loop = true;
            vid.src = src;
            let texture = new THREE.VideoTexture(vid);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            // texture.format = THREE.RGBFormat;
            playPromise(vid)
            return {texture: texture, videoElement: vid}
        },
        playVideo(id, endEvent, loop) {
            // console.log('playing video');
            this.toggleVideoElement(endEvent, loop)

            if (!isMP4()) {
                this.playHLS(id)
            } else {
                this.playMP4(id)
            }
        },
        /**
         * End event toggle is passed from routes and boomerangs
         * removed when video ends, to deal with the 2 video setup
         * @param endEvent
         * @param loop
         */
        toggleVideoElement(endEvent=false, loop=false) {
            const vids = get().videos
            const videoIndex = get().videoIndex
            vids[0].vid.pause()
           /* if (videoIndex === 0) {
                vids[1].vid.pause()
            }*/
            /*if (videoIndex === 1) {
                vids[0].vid.pause()
            }
            if (videoIndex === 0) {
                set(state => ({videoIndex: 1}))
            } else {
                set(state => ({videoIndex: 0}))
            }*/
            set(state => ({videoElement: vids[videoIndex].vid}))



            if (endEvent) {
                // console.log('adding event to ', videoIndex);
                vids[videoIndex].vid.addEventListener("ended", onEnd)
                checkEnd = true
                checkVideoTime()
            } else {
                checkEnd = false
            }
            vids[videoIndex].vid.loop = loop
        },
        playHLS(id) {

            // new HLS
            const hlsObject = get().actions.getHLSObjectByVideoId(id);
            const videoElement = get().videoElement;
            hlsObject.hls.detachMedia(videoElement)
            hlsObject.hls.attachMedia(videoElement)

            /**
             * Detach current HLS object
             */
            playPromise(videoElement)
            syncFader()
            /**
             * Set current HLS to selected video
             */
            set(state => ({currentHLS: hlsObject.hls}))
        },
        playMP4(id) {

            /**
             * Check to see if we're using a static or a video
             */
            // console.log(preloadStore.getState().connectionSpeed)
            if (preloadStore.getState().connectionSpeed === CONNECTION_STATIC) {
                if (preloadStore.getState().videos[id]?.static?.asset) {
                    this.setStatic(preloadStore.getState().videos[id].static.asset)
                    postStore.getState().setDoEffect(true)
                }


                videoStoreApi.getState().actions.setVideoPlaying(true)


                setTimeout(()=> {
                    videoStoreApi.getState().actions.setVideoPlaying(false)
                }, 100)
                // console.log(preloadStore.getState().videos[id].static.asset)
                return
            }
           
            
            
            const videoElement = get().videoElement;
            let mp4
            switch (preloadStore.getState().connectionSpeed) {
                case CONNECTION_FAST:

                    if (get().videoType === VIDEO_TYPE_360) {
                        if (preloadStore.getState()._4kSupport) {
                            mp4 = get().actions.getVideoByID(id).highBitrateMp4
                        } else {
                            // fast connection but no 4k support, serve low bitrate for 360s
                            mp4 = get().actions.getVideoByID(id).lowBitrateMp4
                        }
                    } else {
                        // routes and boomerangs both use high
                        mp4 = get().actions.getVideoByID(id).highBitrateMp4
                    }


                    break
                case CONNECTION_SLOW:
                    mp4 = get().actions.getVideoByID(id).lowBitrateMp4
                    break
                default:
            }

            videoStoreApi.getState().actions.setVideoPlaying(false)
            videoElement.pause()
            videoElement.currentTime = 0
            videoElement.src = mp4
            videoElement.load()
            videoElement.muted = true
            videoElement.playsInline = true

            // playPromise(videoElement)

            function onCanPlay(e) {
                // console.log('canplay', e);
                playPromise(videoElement)
                videoElement.removeEventListener('canplay', onCanPlay);

                videoStoreApi.getState().actions.setVideoPlaying(true)
                // videoStoreApi.getState().actions.setVideoDuration(vid.duration)
                postStore.getState().setDoEffect(true)
                // syncFader()
            }
            videoElement.addEventListener('loadedmetadata', onCanPlay);
            //playPromise(videoElement)
            // syncFader()

            /*setTimeout(()=> {

            }, 1000)*/


            /*if (videoElement.readyState >= videoElement.HAVE_FUTURE_DATA) {
                console.log('video can play! 1');
                playPromise(videoElement)
                syncFader()
            } else {
                videoElement.addEventListener('canplay', playSync);
            }*/

            // videoElement.src = "360_vr_master_series___free_download___crystal_shower_falls (360p).mp4"
            // videoElement.play()
            // console.log('loading', mp4);
            /*const vidboom = document.getElementById("testvid")
            vidboom.pause()
            vidboom.currentTime = 0
            vidboom.src = mp4
            vidboom.loop = true
            vidboom.load()*/
        },
        play360(id, hasEndEvent) {
            set(state => ({videoType: VIDEO_TYPE_360}))
            set(state => ({play2D: false}))

            // id, endEvent, loop
            this.playVideo(id, false, true)
        },
        setPlay2D(v) {
            set(state => ({play2D: v}))
        },
        playRoute(route) {
            // console.log('playRoute', route.routeVideo);
            // delay(1000).then(() => {
            transitionStoreApi.getState().showTransition(true)
            set(state => ({videoType: VIDEO_TYPE_ROUTE}))
            set(state => ({play2D: true}))
            get().videoElement.loop = false;
            get().videoElement.muted = true;
            // get().videoElement.autoPlay = true;

            if (route.boomerangSkippable) {
                this.setCanSkip(true)
            } else {
                this.setCanSkip(false)
            }
            /*get().videoElement.pause()
            get().videoElement.currentTime = 0*/

            /**
             *
             */
            this.playVideo(route.routeVideo, true, false)
            // })
        },
        playBoomerang(boomerang) {
            // console.log('playing boomerang', );
            delay(1000).then(() => {
                // console.log('playing boomerang', );
                set(state => ({play2D: true}))
                transitionStoreApi.getState().showTransition(true)
                set(state => ({videoType: VIDEO_TYPE_BOOMERANG}))

                get().videoElement.loop = false;

                if (boomerang.boomerangSkippable) {
                    this.setCanSkip(true)
                } else {
                    this.setCanSkip(false)
                }

                /*get().videoElement.pause()
                get().videoElement.currentTime = 0*/
                this.playVideo(boomerang.boomerangVideo, true)
            })

        },
        skipVideo() {
            // console.log('skipping', get().videoType);
            document.body.classList.remove("is2d")
            onEnd(get, set)
            onSkip(get)
        },
        setVideoPlaying(v) {
            set(state => ({videoPlaying: v}))
        },
        getVideoPlaying(v) {
            return get().videoElement
        },
        setVideoRestart() {
            sceneStoreApi.getState().actions.doVideoRestart()
            // set(state => ({videoRestart: Date.now()}))
        },
        setVideoDuration(v) {
            set(state => ({videoDuration: v}))
        },
        getVideoDuration(v) {
            return get().videoDuration
        },
        setShowSkip(v) {
            set(state => ({showSkip: v}))
        },
        playVideoAudio(v) {
            if (v) {
                get().videos.forEach(video => {
                    video.vid.muted = false
                })
            } else {
                get().videos.forEach(video => {
                    video.vid.muted = true
                })
            }
        },
        setCanSkip(v) {
            set(state => ({canSkip: v}))
        },
        preloadVideos() {
            if (!isMP4()) {
                preloadActiveVideos(get)
            } else {
                preloadMP4s(get)
            }
        },
        setFragLoaded() {
            set(state => ({fragLoaded: get().fragLoaded+1}))

            // console.log('', get().fragLoaded);
        },
        updateTexture() {

        },
        getTexture() {
            return get().videoTexture
/*            if (get().videoIndex === 0) {
                return get().videoTexture2
            } else {
                return get().videoTexture
            }*/
        },
        setVolume(v) {
            get().videos.forEach(video => {
                if (audioStore.getState().isMuted) {
                    video.vid.volume = 0
                } else {
                    video.vid.volume = v
                }
            })
        },
        setStatic(v) {
            set(state => ({static360: v}))
        }
    }
}))

export function playPromise(videoElement) {
    // console.log('', videoElement.src);
    const playPromise = videoElement.play();
    if (playPromise !== undefined) {
        playPromise.then(_ => {
            // console.log('playing ok', videoElement.src);
        })
            .catch(error => {
                console.log('error', error);
            });
    }
}
/**
 * Put all 2D and 360 videos into one
 * @param v
 * @returns {*[]}
 */
function processVideos(v) {
    let all = []
    // console.log('p', v);
    v.twod.forEach(video => all.push(video))
    v.threed.forEach(video => all.push(video))
    return all;
}

/**
 * TODO: add all other mp4s and streams
 * @param allVideoData
 */
function createHLSStreams(allVideoData) {
    let processedVideos = []
    // console.log('isIOS', isIOS);
    allVideoData.forEach(video => {
        let videoObj = {}
        videoObj.id = video.id

        if (!isMP4()) {
            videoObj.hls = createHLSObject()
            // console.log('', video);
            if (!video.highBitrateHlsStream) {
                // console.log('using low', video);
                videoObj.source = video.lowBitrateHlsStream || null
            } else {
                // console.log('using high', video.highBitrateHlsStream);
                videoObj.source = video.highBitrateHlsStream || null
            }

            if (video.highBitrateHlsStream) {
                if (video.highBitrateHlsStream.toString().includes("youtube")) {
                    videoObj.hls = null
                } else {
                    // videoObj.hls.loadSource(video.highBitrateHlsStream)
                }
            }
        }
        processedVideos.push(videoObj);
    })
    return processedVideos
}

function preloadActiveVideos(get) {
    // console.log('allHLSStreams', get().allHLSStreams);

    // console.log('get().activeVideos', get().activeVideos.length);

    get().activeVideos.forEach(id => {
        let video = get().allHLSStreams.find(x => x.id === id)
        // console.log(video);
        if (video.source.length) {
            video.hls.loadSource(video.source)
        }
    })
}
function preloadMP4s(get) {
    // console.log('activeVideos', get().activeVideos);

    get().activeVideos.forEach((id) => {
        let video = get().allVideoData.find(x => x.id === id)
        if (video.preload || video.static360Image) {
            preloadStore.getState().actions.addVideo(video)
        }
    })
}

function checkVideoTime() {

    let currentVideo = videoStoreApi.getState().videoElement
    if  (currentVideo.duration > 0 && currentVideo.currentTime === 0) {
        // console.log('', currentVideo.currentTime, currentVideo.duration)
    }
    if (globalSetStoreAPI.getState().globalSets) {
        if (currentVideo.currentTime > globalSetStoreAPI.getState().globalSets.experience.skipButtonTiming) {
            if (videoStoreApi.getState().canSkip) {
                videoStoreApi.getState().actions.setShowSkip(true)
            }
        } else {
            videoStoreApi.getState().actions.setShowSkip(false)
        }
    }
    if (checkEnd) {
        requestAnimationFrame(checkVideoTime)
    }
}
function playSync() {
    videoStore.getState().videos.forEach((video)=> {
        video.vid.removeEventListener('canplay', playSync)
    })
    console.log('video can play! 2');
    playPromise(videoStore.getState().videoElement)
    syncFader()
}
function onEnd() {

    checkEnd = false

    videoStore.getState().videoElement.removeEventListener("ended", onEnd)
    // get().videoElement.pause()
    // console.log('videoStore.getState().videoType', videoStore.getState().videoType);
    switch (videoStore.getState().videoType) {
        case VIDEO_TYPE_ROUTE:
            // console.log('end', VIDEO_TYPE_ROUTE);
            videoStore.getState().videoElement.pause()
            routeStoreAPI.getState().actions.onEnd()
            videoStore.setState(state => ({play2D: false}))
            break
        case VIDEO_TYPE_BOOMERANG:
            // console.log('end', VIDEO_TYPE_BOOMERANG);
            videoStore.getState().videoElement.pause()
            boomerangStoreAPI.getState().actions.onEnd()
            videoStore.setState(state => ({play2D: false}))
            break
        default:
            // console.log('on end');
            videoStore.getState().videoElement.play()
            videoStore.getState().actions.setVideoRestart()
    }

    // get().videoElement.loop = true
}

function onSkip(get) {
    switch (get().videoType) {
        case VIDEO_TYPE_BOOMERANG:
            boomerangStoreAPI.getState().actions.doSkip()
            break
        default:
    }
}

export default videoStore;
export {videoStoreApi}

function blah() {
    postStore.getState().setDoEffect(true)
}

function syncFader() {
    let vid = videoStoreApi.getState().videoElement
    if (vid && vid.currentTime > 0.1) {
        // postStore.getState().setDoEffect(true)
        // transitionStoreApi.getState().showTransition(false)
        videoStoreApi.getState().actions.setVideoPlaying(true)
        videoStoreApi.getState().actions.setVideoDuration(vid.duration)
        postStore.getState().setDoEffect(true)
    } else {
        // console.log('waiting', vid.currentTime);
        videoStoreApi.getState().actions.setVideoPlaying(false)
        requestAnimationFrame(syncFader)
    }
}

function isMP4() {
    return true
    // return (isMobile || isSafari)
}


// for 360s with no end event - temporary probably

function checkEndOfVideos() {

    allVideos.forEach(video => {
        // console.log('', video.vid.currentTime, video.vid.duration);

        if (video.vid.currentTime >= video.vid.duration - 0.1) {
            if (video.vid.currentTime > 0) {
                // console.log('END');
                // video.vid.currentTime = 0

                const type = videoStore.getState().videoType
                // console.log('type', type);

                switch (type) {
                    case VIDEO_TYPE_360:
                        videoStore.getState().actions.setVideoRestart()
                        break;
                    default:
                        //onEnd()
                }

            }
        }
    })

    // requestAnimationFrame(checkEndOfVideos)
}
