/*global phenix*/
import create from "zustand";
import {findAllByKey, getValueByKey} from "../../utils/utilities";
import zlib from "zlib";
import crypto from "crypto";
import AuthParams from "../../utils/auth-params";
import * as THREE from "three";
import {query_livestreams} from "../../graphql/queries/livestreams";
import {siteStoreApi} from "../siteStore";
import {query_livestreams_specific} from "../../graphql/queries/livestreams_specific";

const debugToken = "DIGEST:eyJhcHBsaWNhdGlvbklkIjoiZGVtbyIsImRpZ2VzdCI6IjlDQis2TzNpWUJMWVkydUtaaUJnRjRPaGY1OW9nZkt2R1lwTkc5TlhkeEl3eFRRc0NhalZFMWRQMXRQOWVkaWRCRUdUM2dkdk91WWJiSjVsZ2dWeFF3PT0iLCJ0b2tlbiI6IntcImV4cGlyZXNcIjoxOTI1OTg4ODg0MzkzLFwicmVxdWlyZWRUYWdcIjpcImNoYW5uZWxJZDp1cy1ub3J0aGVhc3QjZGVtbyNwaGVuaXhXZWJzaXRlRGVtb1wifSJ9"

const [useLiveStreamStore, useLiveStreamStoreApi] = create((set, get) => ({
    allLivestreams: [],
    currentLivestream: {},
    hasLoadedPhenixRTS: false,
    actions: {
        setAllLivestreams(livestreams) {
            set(state => ({ allLivestreams: processLivestreams(livestreams) }))
        },
        getLivestreamByID(id) {
            return getValueByKey(get().allLivestreams, "id", id)
        },
        setHasLoadedPhenixRTS(v) {
            // console.log('dropping phenix script');
            set(state => ({ hasLoadedPhenixRTS:v }))
        },
        getLivestreamVideoTexture(id, audioEnabled) {
            // console.log(this.getLivestreamByID(id))
            const livestream = this.getLivestreamByID(id)
            return {texture: livestream.texture, videoElement: livestream.videoElement, channel: livestream.channel}
        },
        addLoadListener(id, callback) {
            /**
             * TODO: this needs doing better
             */
            let liveStream = get().actions.getLivestreamByID(id)
            liveStream.onLoad = callback
            console.log('liveStream', liveStream);
        }
    }
}))

function processLivestreams(livestreams) {
    let processedLivestreams = []
    livestreams.forEach(livestream => {
        let processedLivestream = {}
        processedLivestream.id = livestream.id
        processedLivestream.title = livestream.title
        processedLivestream.edgecastEventId = livestream.edgecastEventId
        processedLivestream.activateLiveStream = livestream.activateLiveStream
        processedLivestream.livestreamPending = livestream.livestreamPending
        processedLivestream.blah = "one"


        if (livestream.activateLiveStream && !useLiveStreamStoreApi.getState().hasLoadedPhenixRTS) {
            useLiveStreamStoreApi.getState().actions.setHasLoadedPhenixRTS(true)
        }

        /**
         * get token
         */
        if (livestream.activateLiveStream && livestream.edgecastEventId) {
            processedLivestream.token = getAppData(livestream.edgecastEventId).then((res)=> {
                if (res) {
                    console.log('TOKEN:', res);
                    processedLivestream.token = res.authenticationToken
                    // createLiveVideo(res)
                    const {videoElement, texture} = createVideoElement()
                    processedLivestream.videoElement = videoElement
                    processedLivestream.texture = texture
                    let channel = phenix.Channels.createChannel({
                        videoElement: videoElement,
                        token: processedLivestream.token
                    });
                    processedLivestream.channel = channel
                    channel.mute()

                } else {
                    // console.log('NO STREAMS - using test token');
                    /*console.log('NO STREAMS - using test token');
                    processedLivestream.token = debugToken
                    const {videoElement, texture} = createVideoElement()
                    processedLivestream.videoElement = videoElement
                    processedLivestream.texture = texture
                    let channel = phenix.Channels.createChannel({
                        videoElement: videoElement,
                        token: processedLivestream.token
                    });
                    processedLivestream.channel = channel
                    channel.mute()*/
                }
            })
        }

        /**
         * Livestream pending
         */
        if (!livestream.activateLiveStream && livestream.livestreamPending) {
            pollquery(livestream)
        }

        processedLivestreams.push(processedLivestream)
    })
    return processedLivestreams
}

export default useLiveStreamStore
export {useLiveStreamStoreApi}

function pollquery(livestream) {
    let cto;
    siteStoreApi.getState().actions.getSpecificData(query_livestreams_specific(livestream.id)).then((r)=> {
        // console.log('', r.data);
        const streamActive = r.data.livestreams[0]?.activateLiveStream;
        if (streamActive) {
            clearTimeout(cto)
            /**
             * Stream activated now do the thing
             */
            console.log('%c stream active ', 'background: #1ea700; color: #ffffff');

            livestream.activateLiveStream = true
            useLiveStreamStoreApi.getState().actions.setHasLoadedPhenixRTS(true)

            livestream.token = getAppData(livestream.edgecastEventId).then((res)=> {
                if (res) {
                    console.log('TOKEN:', res);
                    livestream.token = res.authenticationToken
                    // createLiveVideo(res)
                    const {videoElement, texture} = createVideoElement()
                    livestream.videoElement = videoElement
                    livestream.texture = texture
                    let channel = phenix.Channels.createChannel({
                        videoElement: videoElement,
                        token: livestream.token
                    });
                    livestream.channel = channel
                    channel.mute()

                    livestream.loadCallback()

                } else {
                    // console.log('NO STREAMS - using test token');
                    livestream.token = debugToken
                    const {videoElement, texture} = createVideoElement()
                    livestream.videoElement = videoElement
                    livestream.texture = texture
                    let channel = phenix.Channels.createChannel({
                        videoElement: videoElement,
                        token: livestream.token
                    });
                    livestream.channel = channel
                    channel.mute()

                    console.log('livestream', livestream);
                    try {
                        livestream.loadCallback()
                    } catch (e) {
                        console.log('ls', e);
                    }

                }
            })


        } else {
            cto = setTimeout(()=> {
                // console.log('stream pending');
                pollquery(useLiveStreamStoreApi.getState().actions.getLivestreamByID(livestream.id))
            }, 5000)
        }


    })
}

function createVideoElement() {
    const vid = document.createElement("video");
    vid.classList.add("liveVideo")
    vid.crossOrigin = "Anonymous";
    vid.playsInline = true;
    vid.muted = true;
    vid.autoplay = false;
    let texture = new THREE.VideoTexture(vid);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBFormat;
    // document.body.appendChild(vid)
    return {videoElement: vid, texture: texture}
}


const userID = '82f3c3aa7150410ca1be5775e84c8cd8';
const apiKey = 'ZSrSOBgrn+AK8zBu9TRUMO8dwDixXhymoDkH1+UP';
// const event = 'c0f17ca0b05c4245b63756b3f8efee19';

const getToken = async (event, headers, stream) => {
    const body = JSON.stringify({
        "expires_in": 3600,
        "channel_alias": stream.alias,
        "tags": stream.tags
    })
    console.log('', body);

    let response = await fetch(`https://services.uplynk.com/api/v4/rts/events/${event}/token/stream`, {
        headers,
        method: 'POST',
        body: body
    });
    const res = await response.json();
    return res
}

const getAppData = async (event) => {
    const authParams = AuthParams(userID, apiKey);

    const headers = {
        "Content-Type": 'application/json',
        "Authorization": `${authParams.msg} ${authParams.sig}`,
    };
    let response = await fetch(`https://services.uplynk.com/api/v4/rts/events/${event}/manifest`,
        {
            headers
        }
    );
    // console.log('** manifest loaded **');
    const res = await response.json();
    // console.log('', res);
    if (res.event?.streams.length) {
        return(getToken(event, headers, res.event.streams[0]))
    } else {
        return false
    }
}
