import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef} from "react";
import {extend, useFrame, useThree} from "@react-three/fiber";
import CameraControls from "./camera-controls-local/dist/camera-controls.module";
// import CameraControls from "camera-controls";
import gsap from "gsap";
import * as THREE from "three";
import create from "zustand";
import videoStore from "../../../../store/videos/videoStore";
import {getRandomPosNeg, map} from "../../../../utils/utilities";
import globalSetStore from "../../../../store/globalSets/globalSetStore";
CameraControls.install({ THREE })
extend({ CameraControls })
let cto
let canSetTarget = true;
let transition = true
export const [controlsStore, controlsStoreApi] = create((set, get) => ({
    target: 0,
    setTarget: (v, animate=true) => {
        cto = setTimeout(()=> {
            canSetTarget = true
            transition = true
        },100)
        if (canSetTarget) {
            canSetTarget = false
            // console.log('setting target', v);
            transition = animate
            set({target: {t: v, cache: Date.now()}})
        } else {
            // console.log('cannot set target');
        }
    }
}))

const CControls = forwardRef((_, ref) => {
    const camera = useThree((state) => state.camera)
    const gl = useThree((state) => state.gl)
    const controls = useMemo(() => new CameraControls(camera, gl.domElement), [camera, gl.domElement]);
    const currentPos = useRef();
    const saveRotation = useRef();
    const newRotation = useRef();
    const target = controlsStore(state => state.target)
    const play2D = videoStore(state => state.play2D)

    useEffect(()=> {
        const speed = globalSetStore.getState().globalSets.experience.cameraTransitionSpeed
        controls.dampingFactor = map(speed, 0, 1, 0, 0.4)
        controls.enabled = !play2D
    }, [play2D])

    function normaliseAngle(angle) {
        // return Math.asin(Math.sin(angle))
        return Math.atan2(Math.sin(angle), Math.cos(angle));
    }

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    /**
     * Put controls and other components in the store for global access
     */
    useLayoutEffect(()=> {
        controls.addEventListener('controlstart', ()=> {
            gsap.killTweensOf(controls)
        })
        controls.mouseButtons.wheel = CameraControls.ACTION.NONE
        controls.mouseButtons.right = CameraControls.ACTION.NONE
        controls.mouseButtons.middle = CameraControls.ACTION.NONE
        controls.touches.two = CameraControls.ACTION.NONE;
        controls.touches.three = CameraControls.ACTION.NONE;
    }, [controls])

    useFrame(()=> {
        camera.updateProjectionMatrix();

    })

    useEffect(()=> {
        if (!target) return
        setTimeout(()=> {
            _setHotspot([parseFloat(target.t.x), parseFloat(target.t.y), parseFloat(target.t.z)])
        }, play2D ? 0 : 0)

    }, [target])

    /**
     * Array of coords to look at
     * @param pos
     * @private
     */
    // TODO:

    function norm(angle) {
        return THREE.MathUtils.euclideanModulo( angle, 360 * THREE.MathUtils.DEG2RAD );

    }

    function _setHotspot(pos, ignoreCrazySpin=false) {

        currentPos.current = pos;
        saveRotation.current = {theta: controls.azimuthAngle, phi: controls.polarAngle};
        controls.setTarget(pos[0],pos[1],pos[2])
        newRotation.current = {theta: controls.azimuthAngle, phi: controls.polarAngle};

        controls.setTarget(0,0,0)


        if (Math.abs(normaliseAngle(saveRotation.current.theta) - normaliseAngle(newRotation.current.theta)) > Math.PI) {
            controls.rotateTo(norm(saveRotation.current.theta), norm(saveRotation.current.phi), false)
            controls.rotateTo(norm(newRotation.current.theta), norm(newRotation.current.phi), transition)
        }
        else {
            controls.rotateTo(normaliseAngle(saveRotation.current.theta), normaliseAngle(saveRotation.current.phi), false)
            controls.rotateTo(normaliseAngle(newRotation.current.theta), normaliseAngle(newRotation.current.phi), transition)
        }
    }

    return useFrame((state, delta) => controls.update(delta))
});
export default CControls
