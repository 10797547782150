import create from "zustand";
import {findAllByKey, getValueByKey} from "../../utils/utilities";
import {iconStoreApi} from "../icons/iconsStore";
import {isAndroid, isIOS} from "react-device-detect";

const [hotspotStore, hotspotStoreApi] = create((set, get) => ({
    allHotspots: [],
    actions: {
        setAllHotspots(hotspots) {
            set(state => ({ allHotspots: processHotspots(hotspots) }))
        },
        getHotspotByID(id) {
            return getValueByKey(get().allHotspots, "id", id)
        },
        playHotspotVideoTextureAudio(id, val) {
            // return
            let hotspot = getValueByKey(get().allHotspots, "id", id)
            // console.log('playHotspotVideoTextureAudio', val);
            if (hotspot && hotspot.videoElement) {
                hotspot.videoElement.muted = val
            }
            if (val) {
                hotspot.channel?.unmute()
            } else {
                hotspot.channel?.mute()
            }
        },
        setClicked(id) {
            /**
             * Sets a new "clicked" property to true
             * @type {(*|{clicked: boolean}|T)[]}
             */
            let new_array = get().allHotspots.map(element => element.id === id ? {...element, clicked : true} : element);
            set(state => ({ allHotspots: new_array }))
        }
    }
}))

function processHotspots(hotspots) {
    let processedHotspots = []
    hotspots.forEach(hotspot => {

        let processedHotspot = {}
        processedHotspot.actions = hotspot.actionBuilderHotspots[0].children[0].actions
        processedHotspot.override = hotspot.actionBuilderHotspots[0].children[1]
        if (processedHotspot.override !== undefined) {

            const platforms = processedHotspot.override.platform
            if (platforms.includes("ios") && isIOS) {
                processedHotspot.actions = processedHotspot.override.override[0].actions
            }
            if (platforms.includes("android") && isAndroid) {
                processedHotspot.actions = processedHotspot.override.override[0].actions
            }
        }

        let icon = iconStoreApi.getState().actions.getIconByID(
            hotspot.hotspotChooseIcon[0].id
        )
        processedHotspot.id = hotspot.id
        processedHotspot.icon = icon
        processedHotspot.title = hotspot.title
        processedHotspot.hotspotHideOnClick = hotspot.hotspotHideOnClick
        processedHotspot.hotspotLocation = hotspot.hotspotLocation
        processedHotspot.hotspotEffect = hotspot.hotspotEffect

        if (hotspot.hotspotLabel?.length) {
            processedHotspot.hotspotLabel = {
                copy: findAllByKey(hotspot, 'labelCopy')[0],
                size: findAllByKey(hotspot, 'labelSize')[0],
                colour: findAllByKey(hotspot, 'labelColour')[0],
                position: findAllByKey(hotspot, 'labelPosition')[0]
            }
        } else {
            processedHotspot.hotspotLabel = null
        }
        if (hotspot.hotspot3dText?.length) {
            processedHotspot.hotspot3dText = hotspot.hotspot3dText
        } else {
            processedHotspot.hotspot3dText = []
        }
        // console.log(processedHotspot)
        processedHotspots.push(processedHotspot)

    })
    return processedHotspots
}

export default hotspotStore;
export {hotspotStoreApi}
