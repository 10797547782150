import {TextureLoader} from "three";

/**
 * for preloading videos with progress
 * @param url
 * @param dispatch, link to context
 */
export const LoadVideo = async (url, display=false) => {
    // console.log('loadvideo', `${url}`);
    let timeLoadStart, timeLoadEnd
    timeLoadStart = Date.now();
    const response = await fetch(`${url}?cb=${Date.now()}`);

    const length = response.headers.get('Content-Length');
    if (!length) {
        console.log('error loading video - no bytes');
        return await response.arrayBuffer();
    }
    const array = new Uint8Array(length);
    let at = 0;
    const reader = response.body.getReader();
    let downloadIsDone = false
    while (!downloadIsDone) {
        const {done, value} = await reader.read();
        if (done) {
            downloadIsDone = done
            break;
        }
        array.set(value, at);
        at += value.length;
        let progress = (at / length).toFixed(2);
        if (display) {
            display(progress)
        }
    }
    let blob = new Blob([array], {type: 'video/mp4'});
    let videoURL = URL.createObjectURL(blob);

    let bytes = blob.size

    let fileSizeMB = bytes / (1024 ** 2)

    // console.log(`size`, fileSizeMB)ΩΩΩ

    timeLoadEnd = Date.now();
    const loadTime = (timeLoadEnd-timeLoadStart) / 1000
    // console.log(`loadtime`,  loadTime / loadTime)
    // console.log(`bytes/second`,  bytes / loadTime)

    const bits = bytes * 8


    // console.log("loadTime", loadTime)
    // console.log("bits", bits)
    // console.log("Mbps:", (bits/loadTime) / 1000000)
    const bitrate = (bits/loadTime) / 1000000



    // console.log(convertBytes(bytes))

    // let videoURL2 = createObjectURL(blob);
    // console.log('', videoURL);
    // console.log('', videoURL2);


    /*fetch(`${url}`)
        .then(response => response.blob())
        .then(imageBlob => {
            let objectURL = URL.createObjectURL(imageBlob);
            console.log('', objectURL);
        });
    */
    return [videoURL,bitrate];
}

export function createObjectURL(object) {
    return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

/**
 * TODO: rename this to loadTexture
 * @param url
 * @returns {Promise<unknown>}
 */
export function loadTexture(url) {

    return new Promise(resolve => {

        getBase64Image( url )
            .then( (base64Image) => {
                // console.log(`loaded static`, video.static360Image )
                const t = new TextureLoader().load(base64Image)
                resolve(t)
            } );
    });
}
export function loadImage(url) {

    return new Promise(resolve => {

        getBase64Image( url )
            .then( (base64Image) => {
                resolve(base64Image)
            } );
    });
}


export function getContentPath(asset) {
    if (!asset) return;
    let path;
    if(window.location.href.indexOf('localhost') !== -1){
        path = process.env.REACT_APP_API_LOCAL;
    } else {
        path = process.env.REACT_APP_API_PROD_DATA;
    }
    return `${path}content/${asset}`;
}

export function removeGraphQLBlankObjects(arr) {
    return arr.filter(value => Object.keys(value).length !== 0);
}




// https://gist.github.com/colxi/c9ab898aa063e0943d4fae1840b982d8
// get an image blob from url using fetch
let getImageBlob = function(url){
    return new Promise( async resolve=>{
        let response = await fetch( url );
        let blob = response.blob();
        resolve( blob );
    });
};

// convert a blob to base64
let blobToBase64 = function(blob) {
    return new Promise( resolve=>{
        let reader = new FileReader();
        reader.onload = function() {
            let dataUrl = reader.result;
            resolve(dataUrl);
        };
        reader.readAsDataURL(blob);
    });
}

// combine the previous two functions to return a base64 encode image from url
export const getBase64Image = async function( url ){
    let blob = await getImageBlob( `${url}` );
    // let blob = await getImageBlob( `${url}?cb=${Date.now()}` );
    let base64 = await blobToBase64( blob );
    return (base64);
}

/**
 *
 * @param bytes
 * @param loadTime (s)
 * @returns {number}
 */
const getBitRate = function(bytes, loadTime) {
    const bits = bytes * 8
    return (bits/loadTime) / 1000000
}
