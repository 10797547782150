import {useAnimationFrame, useKeyPress} from "../../../utils/hooks";
import {useLoader, useThree} from "@react-three/fiber";
import React, {Suspense, useRef} from 'react'
import {
     Vector2,
     RGBAFormat,
     FramebufferTexture,
     DoubleSide,
     TextureLoader,
     SpriteMaterial,
     MeshLambertMaterial
} from "three";
import {useAnimations} from "@react-three/drei";

let vector = new Vector2();
const texture = new FramebufferTexture( 512, 512, RGBAFormat );
const dpr = window.devicePixelRatio;
const textureSize = 128 * dpr;

let spriteMaterial
function TestRender() {
     console.log('testrender');

     const {gl} = useThree()

     useAnimationFrame(()=> {
          /*gl.copyFramebufferToTexture(vector, texture);
          gl.clearDepth()
          spriteMaterial = new SpriteMaterial( { map: texture } );
          spriteMaterial.needsUpdate = true*/
     })




     const pref = useRef()

     return (
         <group position={[0, 0, 1]} >

              <mesh ref={pref}>
                   <planeBufferGeometry attach="geometry" args={[3, 3]} />
                   <meshBasicMaterial attach="material" color={"red"} side={DoubleSide} map={texture}/>
              </mesh>

         </group>
     )
}
export default TestRender
