import './App.scss';
import {Route, Routes} from "react-router-dom";
import PageHome from "./pages/PageHome";
import Preloader from "./sitecomponents/Preloader";
import {useEffect, useRef} from "react";
import siteStore from "./store/siteStore";
import PageExperience from "./pages/PageExperience";
import preval from "preval.macro";
import {Helmet} from "react-helmet";
import globalSetStore from "./store/globalSets/globalSetStore";
import {isDesktop} from "react-device-detect";
import CookiesAndTracking from "./sitecomponents/CookiesAndTracking";
import Edgecast from "./store/livestreams/Edgecast";
import gsap from "gsap"
import Error from "./sitecomponents/Error";
import {isInFolder} from "./utils/utilities";
import GamePad from "./gamepad/GamePad";
import LucozadeTracking from "./sitecomponents/LucozadeTracking";

function App() {
    const actions = siteStore(state => state.actions)
    const fontsLoaded = siteStore(state => state.fontsLoaded)
    const globalSets = globalSetStore(state => state.globalSets)

    useEffect(() => {
        if (!fontsLoaded) return
        if (fontsLoaded) {
            // gsap.to("body", {duration: 1, alpha: 1})
            // document.querySelector(".App").style.opacity = 1
        }
    }, [fontsLoaded])


    useEffect(() => {
        if (isDesktop) {
            document.body.classList.add("desktop")
        }
        if (!isDesktop) {
            document.body.classList.add("device")
        }
    }, [])

    useEffect(()=> {
        // attempt to stop swipe navigation on iOS
        const element = document.querySelector('div');

        element.addEventListener('touchstart', (e) => {
            // is not near edge of view, exit
            if (e.touches[0].pageX > 20 && e.touches[0].pageX < window.innerWidth - 20) return;
            // prevent swipe to navigate back gesture
            // console.log('prevent')
            e.preventDefault();
        });
    }, [])

    useEffect(()=> {
        let url = new URL(window.location.href)
        let data = url.hash.replace('#','')
        // console.log('data', data);
        actions.setHandle(data ? data : "starterSite")
        if (isInFolder("preview") || isInFolder("cc") || isInFolder("cc2")) console.log("Build date::", preval`module.exports = new Date().toLocaleString();`);
    }, [])

    function getTitle() {
        if (globalSets && globalSets.meta.title)  {
            return globalSets.meta.title
        } else {
            return "Loading...";
        }
    }

    return (
        // <meta property="og:title" content={`${globalSets && globalSets.meta.title}`}/>
        <div className="App" style={{opacity: 0}}>
            <Helmet>

                <meta charSet={`${globalSets && globalSets.meta.ogLocale}`}/>
                <meta name="theme-color" content="#FFFFFF"/>
                <meta name="msapplication-TileColor" content="#FFFFFF"/>
                <meta name="msapplication-TileImage" content={`${globalSets && globalSets.meta.favicon.favicon_144x144}`}/>
                <link rel="mask-icon" href={`${globalSets && globalSets.meta.favicon.favicon_48x48}`} color="#000000"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_16x16}`} sizes="16x16"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_32x32}`} sizes="32x32"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_57x57}`}
                      sizes="57x57"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_60x60}`}
                      sizes="60x60"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_72x72}`}
                      sizes="72x72"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_76x76}`}
                      sizes="76x76"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_114x114}`}
                      sizes="114x114"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_120x120}`}
                      sizes="120x120"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_144x144}`}
                      sizes="144x144"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_152x152}`}
                      sizes="152x152"/>
                <link rel="apple-touch-icon-precomposed" href={`${globalSets && globalSets.meta.favicon.favicon_180x180}`}
                      sizes="180x180"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_36x36}`} sizes="36x36"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_48x48}`} sizes="48x48"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_72x72}`} sizes="72x72"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_96x96}`} sizes="96x96"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_144x144}`} sizes="144x144"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_192x192}`} sizes="192x192"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_256x256}`} sizes="256x256"/>
                <link rel="icon" href={`${globalSets && globalSets.meta.favicon.favicon_384x384}`} sizes="384x384"/>
                <meta name="viewport" content="width=device-width,user-scalable=no,minimum-scale=1,maximum-scale=1"/>
                <meta name="theme-color" content="#FFFFFF"/>
                <meta name="keywords" content={`${globalSets && globalSets.meta.metaKeywords}`}/>
                <meta name="author" content={`${globalSets && globalSets.meta.metaAuthor}`}/>
                <link rel="canonical" href={`${globalSets && globalSets.meta.metaUrl}`}/>
                <meta property="og:site_name" content={`${globalSets && globalSets.meta.ogSiteName}`}/>
                <meta property="og:title" content={`${globalSets && globalSets.meta.title}`}/>
                <meta property="og:url" content={`${globalSets && globalSets.meta.metaUrl}`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:description"
                      content={`${globalSets && globalSets.meta.metaDescription}`}/>
                <meta property="og:image"
                      content={`${globalSets && globalSets.meta.ogImage}`}/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="627"/>
                <meta property="og:locale" content="en_GB"/>
                <meta name="twitter:title" content={`${globalSets && globalSets.meta.title}`}/>
                <meta name="twitter:image"
                      content={`${globalSets && globalSets.meta.ogImage}`}/>
                <meta name="twitter:url" content={`${globalSets && globalSets.meta.metaUrl}`}/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:description"
                      content={`${globalSets && globalSets.meta.metaDescription}`}/>
                <meta name="description"
                      content={`${globalSets && globalSets.meta.metaDescription}`}/>
                <title>{`${getTitle()}`}</title>
            </Helmet>

            <Preloader/>
            {/*<div className="buildnumber" ref={buildRef}>
                <div>Build Date: {preval`module.exports = new Date().toLocaleString();`}.</div>
            </div>*/}
            <Routes>
                <Route path="/" element={<PageHome/>} exact/>
                <Route path="/experience" element={<PageExperience/>} exact/>
            </Routes>
            <Edgecast />
            <Error />
            <GamePad />
            {/*<Debug/>*/}
            {/*<Uplynk />*/}
        </div>
    );
}

export default App;
