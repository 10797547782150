import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import "./_loadSpinner.scss"
import {preloadStore} from "../../../../sitecomponents/Preloader";

function LoadSpinner() {
    const complete = preloadStore(state => state.complete)
    const preloadRef = useRef()
    useEffect(() => {
        if (!complete) return;
        gsap.to(preloadRef.current, {duration: 0.3, alpha: 0, onComplete: ()=> {
                preloadRef.current.style.display = "none"
            }})

    }, [complete])
    return (
         <div className="loadspinner" ref={preloadRef}>
             <div className="loader">
                 <div className="face">
                     <div className="circle"/>
                 </div>
             </div>
         </div>
    )
}

export default LoadSpinner
