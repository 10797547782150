import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";

export const query_hotspots = () => `
  hotspots: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on hotspots_default_Entry {
      id
      title
      hotspotHideOnClick
      hotspotEffect
      hotspotChooseIcon {
        id
      }
      hotspotLocation {
        x
        y
        z
      }
      hotspotLabel {
        ... on hotspotLabel_labelContent_BlockType {
          labelCopy: copy
          labelSize: fontSize
          labelColour: fontColour
        }
        ... on hotspotLabel_labelPosition_BlockType {
          labelPosition: offset {
            z
            y
            x
          }
        }
      }
      hotspot3dText {
        ... on hotspot3dText_copyline_BlockType {
          copy
        }
      }
      actionBuilderHotspots {
        ... on actionBuilderHotspots_hotspot_BlockType {
          children {
            ... on actionBuilderHotspots_actions_BlockType {
             ${query_actions}
            }
            ... on actionBuilderHotspots_mobileOverride_BlockType {
               platform
               override: children {
                ... on actionBuilderHotspots_actions_BlockType {
                  ${query_actions}
                 }
               }
             }
          }
        }
      }
    }
  }
`
