import {useEffect, useRef} from "react";
// import IconHotspot from "../../../three/hotspots/hotspotComponents/IconHotspot";
import {actionsStoreApi} from "../../../../../store/actions/actionStore";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import themeStore from "../../../../../store/themes/themeStore";
import {trackEvent, TrackingLabels} from "../../../../../tracking/Tracking";
import {useControl} from "../../../../../utils/hooks";
import siteStore, {STATE_HOME, STATE_MODAL, STATE_PAGE} from "../../../../../store/siteStore";
import sceneStore from "../../../../../store/scenes/sceneStore";


function ModalActions({buttons, direction, modal}) {
     const theme = themeStore(state => state.theme)
     const buttonIndex = useRef(0)

     function Control() {
          const keyPress = useControl(STATE_MODAL, true)
          useEffect(()=> {
               if (buttons && buttons.length) {
                    document.querySelectorAll(`.uibutton`).forEach(button => {
                         button.classList.remove("uibutton--active")
                    })
                    document.querySelector(`[data-index='0']`).classList.add("uibutton--active")
               }
          }, [])
          useEffect(()=> {
               if (!keyPress) return
               // console.log(`modal action`, keyPress.key)
               switch (keyPress.key) {
                    case "A":
                         console.log(`buttonIndex.current`, buttonIndex.current)
                         onClick(buttonIndex.current)
                         buttonIndex.current = 0
                         break
                    case "UP":
                         if (buttonIndex.current === 0) {
                              buttonIndex.current = 0
                         } else {
                              buttonIndex.current -= 1
                         }
                         break
                    case "DOWN":
                         if (buttonIndex.current >= buttons.length-1) {
                              buttonIndex.current = 0
                         } else {
                              buttonIndex.current += 1
                         }
                         break
                    case "LEFT":
                         if (buttonIndex.current === 0) {
                              buttonIndex.current = 0
                         } else {
                              buttonIndex.current -= 1
                         }
                         break
                    case "RIGHT":
                         if (buttonIndex.current >= buttons.length-1) {
                              buttonIndex.current = 0
                         } else {
                              buttonIndex.current += 1
                         }
                         break
                    default:
               }
               if (buttons && buttons.length) {
                    document.querySelectorAll(`.uibutton`).forEach(button => {
                         button.classList.remove("uibutton--active")
                    })
                    document.querySelector(`[data-index='${buttonIndex.current}']`).classList.add("uibutton--active")
               }
          }, [keyPress])
          return null
     }



     const pageState = siteStore(state => state.pageState)
     useEffect(()=> {
          if (pageState !== STATE_MODAL) {
               // console.log(`resetting`)
               buttonIndex.current = 0
          }
     }, [pageState])

     useEffect(() => {

          // console.log('', buttons);
     }, [buttons])

     function onClick(index) {
          // TODO: sort this
          let actions = buttons[index]?.actions

          if (!actions) return

          switch (buttons[index]?.button) {
               case "primary":

                    break
               case "secondary":

                    break
               default:
          }
          // console.log(actions)
          try {
               doActions(actions);
               // tracking
               if (modal) {
                    const modal_name = modal.content.title
                    const button_label = buttons[index].button
                    trackEvent(
                        {
                             name: buttons[index].buttonType === "primary" ? TrackingLabels.names.PRIMARY_CLICK : TrackingLabels.names.SECONDARY_CLICK,
                             category: TrackingLabels.categories.MODAL,
                             label: `modal_${modal_name}_${button_label}`
                        }
                    )
                    // end tracking
               }
          } catch (e) {
               console.log(e)
          }
     }

     // `${direction==="vertical" ? 'column' : 'row'}`
     return (
         <>
         {
         (buttons && buttons.length) ?
             <div
                 className={`uibutton__container ${direction === "vertical" ? 'uibutton__container--column' : 'uibutton__container--row'}`}>
                  {
                      buttons && buttons.map((button, index) => (
                          // cms seems to be spitting out a blank button in [0]
                          (button.hasOwnProperty("button")) ?
                              <div
                                  data-index={index}
                                  className={`uibutton ${button.buttonType === "primary" ? "uibutton__primary" : "uibutton__secondary"}`}
                                  style={button.buttonType === "primary" ? theme.buttonStyle : theme.buttonStyleSecondary}
                                  onClick={() => onClick(index)}
                                  key={index}>{button.button}</div>
                              : null
                      ))
                  }
                  <Control/>
             </div>
             : null
         }
         </>
     )
}

export default ModalActions
