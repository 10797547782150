import Interweave from "interweave";
import {useEffect, useRef} from "react";
import {videoStoreApi} from "../../../../../store/videos/videoStore";
import ReactPlayer from 'react-player';
import gsap from "gsap"
import create from "zustand";
import modalStore from "../../../../../store/modals/modalStore";
import audioStore from "../../../../../store/audio/audioStore";
import {modalTypeStore} from "../Modal";
import {doActions} from "../../../../../store/actionBuilderProcessing";

export const [modalVideoStore] = create((set) => ({
    videoID: 0,
    time: 0,
    playing: false,
    cancelOnReady: false,
    startTime: 0,
    iframe: false,
    subtitles: [],
    setVideoID: (v) => set({videoID: v}),
    setPlaying: (v) => set({playing: v}),
    setStartTime: (v) => set({startTime: v}),
    setIframe: (v) => set({iframe: v}),
    setTime: (v) => set({time: {t: v, cb: Date.now()}}),
    setCancelOnReady: (v) => set({cancelOnready: v}),
    setSubtitles: (v) => set({subtitles: v})
}))

function ModalVideo({content}) {



    const currentVolume = audioStore(state => state.currentVolume)
    useEffect(()=> {
        let video = videoStoreApi.getState().actions.getVideoByID(content.videoID)
        let arrSubs = []
        video.subtitles.forEach((sub, i) => {
            arrSubs.push({kind: 'subtitles', src: sub.chooseVttFile[0].url, srcLang: sub.sublanguage, default: i===0})
        })
        setSubtitles(arrSubs)
        if (!video) return
        let vid;
        if (video.highBitrateMp4) {
            vid = video.highBitrateMp4
        } else {
            vid = video.lowBitrateMp4
        }

        /**
         * Check if prefer HLS
         */
        if (content.preferHls) {
            vid = video.highBitrateHlsStream
        }

        /**
         * Check if start time
         */
        if (video.startTime) {
            setStartTime(video.startTime)
        }
        setVideoID(vid)
    }, [])


    useEffect(()=> {
        // console.log('currentVolume', currentVolume);
    }, [currentVolume])


    const playerRef = useRef()
    const id = modalVideoStore(state => state.videoID)
    const setVideoID = modalVideoStore(state => state.setVideoID)
    const playing = modalVideoStore(state => state.playing)
    const setPlaying = modalVideoStore(state => state.setPlaying)
    const startTime = modalVideoStore(state => state.startTime)
    const setStartTime = modalVideoStore(state => state.setStartTime)
    const setIframe = modalVideoStore(state => state.setIframe)
    const subtitles = modalVideoStore(state => state.subtitles)
    const setSubtitles = modalVideoStore(state => state.setSubtitles)
    const iframe = modalVideoStore(state => state.iframe)
    const time = modalVideoStore(state => state.time)
    const setCancelOnReady = modalVideoStore(state => state.setCancelOnReady)
    const cancelOnReady = modalVideoStore(state => state.cancelOnReady)
    const closeModal = modalStore(state => state.closeModal)
    const openModal = modalStore(state => state.openModal)
    const setVideoEnded = modalTypeStore(state => state.setVideoEnded)

    useEffect(()=> {
        setCancelOnReady(true)
        if (time.t < playerRef.current.getDuration()) {
            playerRef.current.seekTo(time.t)
        }
    }, [time])

    useEffect(()=> {
        if (!closeModal) return
        setPlaying(false)
        setStartTime(0)
        setCancelOnReady(false)
        document.body.classList.remove("portraitvid")

    }, [closeModal])

    useEffect(()=> {
        if (!closeModal) {
            document.querySelector(".videoWrapper").style.opacity = 0;
            setPlaying(true)
        }
    }, [openModal])

    function onEnded() {

        if (content.videoEvents.length && content.videoEvents[0].modalVideoEvents === "onVideoComplete") {
            // console.log('', content.videoEvents[0].actions);
            doActions(content.videoEvents[0].actions);
        }
        // setVideoEnded()
    }

    useEffect(()=> {
        setTimeout(()=> {
            if (playerRef.current) playerRef.current.seekTo(startTime)
        }, 60)

    }, [startTime])

    function onReady() {
        let video= document.querySelector(".videoWrapper").querySelector("video")
        if (!video) {
            console.log('ID', id);
            setIframe(true)
            doIframe()
            return
        }
        setTimeout(()=> {

            if (video.getBoundingClientRect().width > video.getBoundingClientRect().height) {
                video.style.width = "100%"
                video.style.height = "auto"

                document.body.classList.remove("portraitvid")
            } else {
                document.body.classList.add("portraitvid")

            }
            try {
                document.querySelector(".videoWrapper").style.opacity = 1;
            } catch(e) {
            }


            /*gsap.set('.videoWrapper', {scale: 0.95, opacity: 0})
            gsap.to('.videoWrapper', {duration: 0.42, opacity: 1, scale: 1})*/
        }, 300)
    }

    function doIframe() {
        document.querySelector(".videoWrapper").style.opacity = 1;
    }

    return (
        <div className={`${content.modalVideoFull ? "reactplayer" : ""}`}>
            <div className="videoWrapper">
            <ReactPlayer ref={playerRef} className="reactplayer"
                         volume={parseFloat(currentVolume)} controls={true}
                         loop={false} muted={false} playing={playing} url={`${id}`} playsinline onEnded={onEnded}
                         onReady={onReady}

                         config={{ file: {
                                 attributes: {
                                     crossOrigin: 'true'
                                 },
                                 tracks: subtitles
                             }}}

            />
                {
                    /**
                     * No idea how this works but adding this solves the cors youtube problem
                     */
                    iframe ?
                        <iframe
                            className="iframeyoutube w-auto sm:w-[80%] h-60 sm:h-80 sm:mx-4 sm:mb-4"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        : null
                }
            </div>
            {
                (content.modalSubCopy && !content.modalVideoFull) ?
                    <div className="modaldev__videocopy">
                        <Interweave content={content.modalSubCopy}/>
                    </div>
                    : null
            }
        </div>
    )
}

export default ModalVideo
