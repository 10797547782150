import {forwardRef, memo, useEffect, useImperativeHandle, useRef, useState} from "react";
import * as THREE from "three";
import {useLoader} from "@react-three/fiber";
import videoStore, {playPromise} from "../../../../../../store/videos/videoStore";
import iconStore from "../../../../../../store/icons/iconsStore";
import {hotspotStoreApi} from "../../../../../../store/hotspots/hotspotStore";
import useLiveStreamStore from "../../../../../../store/livestreams/livestreamStore";
import {fullscreenVideoStoreApi} from "../../../FullScreenVideo.js/FullScreenVideo";

const Icon = ({hotspot, iconActive}) => {


    const texture = useLoader(THREE.TextureLoader, hotspot.icon.url)
    const actions = videoStore(state => state.actions)
    const liveStreamActions = useLiveStreamStore(state => state.actions)
    const iconActions = iconStore(state => state.actions)
    const [vidTexture, setVidTexture] = useState()
    const meshRef = useRef()

    useEffect(()=> {

    }, [])


    useEffect(()=> {
        if (!hotspot) return
        // console.log('ICON');
        /**
         * Video textures
         */
        if (hotspot.icon.hotspotVideo?.length && !vidTexture) {

            const {hotspotVideo, hotspotIconVideoTextureAudioEnabled} = hotspot.icon;

            let _hotspot = hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
            _hotspot.channel?.unmute()
            /**
             * Have we already created a videoElement and texture
             */
            function getTexture() {

                    return actions.getHotspotVideoTexture( actions.getVideoByID(hotspotVideo).highBitrateMp4, hotspotIconVideoTextureAudioEnabled)
            }

            if (iconActions.hasVideoElement(hotspot.id)) {
                /**
                 * Normal video
                 * @private
                 */

                _hotspot.channel?.unmute()
                setVidTexture(_hotspot.videoTexture)
                playPromise(_hotspot.videoElement)


            } else {
                // console.log('hotspot.id', hotspot.id);

                /**`
                 * Are we using livestream
                 */
                let liveStream = (liveStreamActions.getLivestreamByID(hotspot.icon.hotspotVideo));
                // console.log('', liveStream);
                if (liveStream?.edgecastEventId) {
                    // console.log('LIVE');
                    if (liveStream.token && liveStream.activateLiveStream) {
                        const {texture, videoElement, channel} =
                            liveStreamActions.getLivestreamVideoTexture(hotspot.icon.hotspotVideo, hotspotIconVideoTextureAudioEnabled)
                        setVidTexture(texture)
                        iconActions.setVideoElement(hotspot.id, videoElement, texture, channel)
                        _hotspot.channel?.unmute()

                        /**
                         * Fullscreen video
                         */
                        fullscreenVideoStoreApi.getState().setTexture(texture)

                    } else {
                        liveStream.loadCallback = ()=> {
                            console.log('loaded ***');
                            doStuffOnIcon()
                        }
                        // console.log('stream not activated or token missing');
                        // console.log('videoEntry', liveStream);


                    }
                }

                else {
                    // console.log('NORMAL');
                    const {texture, videoElement} = getTexture()
                    setVidTexture(texture)
                    iconActions.setVideoElement(hotspot.id, videoElement, texture)
                    fullscreenVideoStoreApi.getState().setTexture(texture)
                }
            }

        }
        return ()=> {
            /**
             * If this has a video texture then stop it playing
             */
            if (iconActions.hasVideoElement(hotspot.id)) {
                let _hotspot = hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
                _hotspot.videoElement.pause();
                _hotspot.videoElement.currentTime = 0;
                _hotspot.channel?.mute()
            }
        }
    }, [])

    function doStuffOnIcon() {
        console.log('%c WHOOO ', 'background: #ffcc00; color: #000000');
        const {hotspotVideo, hotspotIconVideoTextureAudioEnabled} = hotspot.icon;
        let _hotspot = hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
        _hotspot.channel?.unmute()
        const {texture, videoElement, channel} =
            liveStreamActions.getLivestreamVideoTexture(hotspot.icon.hotspotVideo, hotspotIconVideoTextureAudioEnabled)
        setVidTexture(texture)
        iconActions.setVideoElement(hotspot.id, videoElement, texture, channel)
        console.log('iconActive', iconActive);
        if (_hotspot.active) {
            _hotspot.channel?.unmute()
        }

        /**
         * Fullscreen video
         */
        fullscreenVideoStoreApi.getState().setTexture(texture)
    }


    return (
        <mesh
            scale={0.05 * (hotspot.icon.iconScale ? hotspot.icon.iconScale : 1)}
            ref={meshRef}
            userData={{savedOpacity: hotspot.icon.hotspotOpacity ? hotspot.icon.hotspotOpacity : 1}}
        >
            <planeBufferGeometry args={[128, getHeight(texture.image)]}/>
            <meshBasicMaterial side={THREE.DoubleSide} map={vidTexture ? vidTexture : texture}
                               opacity={hotspot.icon.hotspotOpacity ? hotspot.icon.hotspotOpacity : 1}
                               transparent={true}/>
        </mesh>
    )
}
export default Icon

function getHeight(image) {
    return (image.naturalHeight / image.naturalWidth) * 128
}
