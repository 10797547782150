import {ShaderMaterial, TextureLoader, sRGBEncoding, DoubleSide} from "three"
import React, {memo, useEffect, useMemo, useRef, useState} from "react"
import * as THREE from 'three'
import videoStore, {videoStoreApi} from "../../../../store/videos/videoStore";
import create from "zustand";
import gsap from "gsap";
import {useLoader} from "@react-three/fiber";
import {useKeyPress} from "../../../../utils/hooks";
import {CONNECTION_STATIC, preloadStore} from "../../../../sitecomponents/Preloader";
import {isIOS} from "react-device-detect";

const video3DStore = create((set, get) => ({
     opacity: 0.6,
     texture: null,
     setOpacity: (v) => set({opacity: v}),
     setTexture: (v) => set({texture: v})
}))

const Sphere360 = memo(() => {
     /*const dummyTexture = new TextureLoader().load('AdobeStock_386827376(1).jpeg')
      const [imgTexture] = useState(dummyTexture)*/

     const _2dTexture = useLoader(THREE.TextureLoader, "blank.png")

     const play2D = videoStore(state => state.play2D)
     const videoPlaying = videoStore(state => state.videoPlaying)
     const static360 = videoStore(state => state.static360)
     const texture = video3DStore(state => state.texture)
     const setTexture = video3DStore(state => state.setTexture)
     const matRef = useRef()


     useEffect(()=> {
          if (!static360) return
          if (preloadStore.getState().connectionSpeed === CONNECTION_STATIC) {
               // console.log(`update static`, static360)
               if (static360) {
                    setTexture(static360)
               }
               matRef.current.transparent = false
               matRef.current.opacity = 1
          }
/*          setTimeout(()=> {
               setTexture(static360)
               matRef.current.transparent = false
               matRef.current.opacity = 1
          }, 100)*/
     }, [static360])

/*     useEffect(() => {
          if (videoPlaying && play2D) {
               setTexture(null)
          }
          /!**
           * This changes the 360 texture
           *
           *!/
          if (videoPlaying && !play2D) {
               // console.log("showing 3d")
               let t = videoStoreApi.getState().actions.getTexture()
               matRef.current.transparent = false
               matRef.current.opacity = 1
               setTexture(t)
          }
     }, [videoPlaying])*/


     useKeyPress("t", ()=> {
          // console.log(``, static360)
          setTexture(static360)
          matRef.current.transparent = false
          matRef.current.opacity = 1
     })

     function getVideo() {
          // console.log(`setting initial 360`)
          const vid = document.getElementById(`newvid1`)
          // const vid = document.createElement("video");
          /*vid.classList.add("previewVideo")
          vid.crossOrigin = "Anonymous"
          vid.playsInline = true
          vid.muted = true
          vid.loop = true*/
          // vid.src = "blank-0.2-sec.m4v"
          let tex = new THREE.VideoTexture(vid)
          setTexture(tex)

          matRef.current.transparent = false
          matRef.current.opacity = 0
          // vid.play()
     }

     useEffect(()=> {
          if (preloadStore.getState().connectionSpeed !== CONNECTION_STATIC)
          {
               getVideo()
          } else {
               setTexture(_2dTexture)
          }
     }, [])


     return (
         <group visible={isIOS ? !play2D : true}>
              <mesh position={[0, 0, 0]}
                    scale={[-1, 1, 1]}
                  // material={sphereShaderMaterial}
              >
                   {/*<meshBasicMaterial transparent opacity={0.1} map={_2dTexture} attach="material" ref={matRef} side={THREE.DoubleSide} />*/}
                   <meshBasicMaterial attach="material" side={DoubleSide} map={texture} ref={matRef} transparent={false}/>
                   <sphereBufferGeometry attach="geometry" args={[1850, 32, 32]}/>
              </mesh>
         </group>
     )
})

export default Sphere360
