import {Color} from "three/build/three.module";
import {isIOS, isMobile, isSafari} from 'react-device-detect';
import {trackEvent, TrackingLabels} from "../tracking/Tracking";

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export function clamp(a, b, c) {
    return Math.max(b, Math.min(c, a));
}

export function isIE() {
    function ieVersion(uaString) {
        uaString = uaString || navigator.userAgent;
        let match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
        if (match) return parseInt(match[2])
    }

    return ieVersion() !== undefined;
}

export function getIndexByKeyValue(array, key, value) {
    return array.map(e => e[key]).indexOf(value);
}

export function getValueByKey(array, key, value) {
    // console.log('', value);
    return array[getIndexByKeyValue(array, key, value)]
}

export function searchArray(value, key, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i][key] === value) {
            return arr[i];
        }
    }
}

export function clampMap(x, in_min, in_max, out_min, out_max, clampMin, clampMax) {
    const map = (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    return clamp(map, clampMin, clampMax);
}

export function map(x, in_min, in_max, out_min, out_max) {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export function sortArrayByObjectKey(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function getObjectByKey(object, value) {
    Object.keys(object).find(key => object[key] === value)
}

export function getTranslateValues(element) {
    const style = window.getComputedStyle(element)
    const matrix = style.transform || style.webkitTransform || style.mozTransform

    // No transform property. Simply return 0 values.
    if (matrix === 'none') {
        return {
            x: 0,
            y: 0,
            z: 0
        }
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d'
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
        return {
            x: matrixValues[4],
            y: matrixValues[5],
            z: 0
        }
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
        return {
            x: matrixValues[12],
            y: matrixValues[13],
            z: matrixValues[14]
        }
    }
}

/**
 *
 * @param str: a string
 * @param n
 * @param useWordBoundary
 * @returns {string|*}
 */
export function truncate(str, n, useWordBoundary) {
    if (str.length <= n) {
        return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "&hellip;";
};

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export function getRandomPosNeg(range) {
    return (Math.random() * range) * (Math.random() > 0.5 ? 1 : -1);
}

export function rnd(v) {
    if (v !== undefined) {
        return (Math.random() * v) * (Math.random() > 0.5 ? 1 : -1);
    }
    return Math.random() > 0.5;
}

export function normalizeAngle(angle) {
    return Math.atan2(Math.sin(angle), Math.cos(angle));
}

/**
 *
 * @param first x,y
 * @param second x,y
 * @returns {number}
 */
export function getDistanceBetweenPoints(first, second) {
    return Math.hypot((second.x - first.x), (second.y - first.y));
}

export function roundTo(x, num) {
    // return x;
    num = 1;
    return Math.ceil(x / num) * num;
}

// convert hex color to glsl
export function hexToGL(hexStr) {
    //console.log('hexToGL');
    //check if valid hex value
    if (/^#([0-9A-F]{3}){1,2}$/i.test(hexStr)) {
        let col = new Color(hexStr);
        let out = col.toArray().map((x) => {
            //to fixed 3
            let conv = Math.round(x * 1000) / 1000;
            //append missing periods
            if (conv.toString().indexOf('.') === -1) conv += '.';
            return conv;
        });
        return `vec3(${out})`;
    } else {
        return '';
    }
}

// convert hex color to array of glsl
export function hexToGLArray(hexStr) {
    //check if valid hex value
    if (/^#([0-9A-F]{3}){1,2}$/i.test(hexStr)) {
        let col = new Color(hexStr);
        let out = col.toArray().map((x) => {
            //to fixed 3
            let conv = Math.round(x * 1000) / 1000;
            //append missing periods
            if (conv.toString().indexOf('.') === -1) conv += '.';
            return conv;
        });
        let modCol = `vec3(${out})`.replace("vec3", "");
        modCol = modCol.replace("(", "");
        modCol = modCol.replace(")", "");
        modCol = modCol.split(',');
        return modCol;
    } else {
        return '';
    }
}

export function checkIOS() {
    // const isIOS =  /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    return isIOS;
}

export function randString() {
    return `label${Math.random().toString(36).substring(7)}`;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomIntPosNeg(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return (Math.floor(Math.random() * (max - min + 1)) + min) * (Math.random() > 0.5 ? 1 : -1);
}

export function getExtension(filename) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
}

export function deviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
}

export function getCookie(name) {
    let value = '; ' + document.cookie;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


export function checkVersion() {
    var agent = window.navigator.userAgent,
        start = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
        return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
}

export function isMobileSafari() {
    return (isSafari && isIOS);
}

export function getAnimDuration(v) {
    return isMobile ? 0 : v;
}

export function checkStaging() {
    if ((window.location.href.indexOf("localhost") > 0) || (window.location.href.indexOf("myjl.ryotstudioproductions") > 0) || (window.location.href.indexOf("drive.ryotstudioproductions") > 0)) {
        return true;
    } else {
        return false;
    }
}

/**
 *
 * @param v
 */
export function logStage(v) {
    if (checkStaging()) {
        console.log(v);
    }
}


// Average of array
export function average(nums) {
    return nums.reduce((a, b) => (a + b)) / nums.length;
}

export function myNewFunction(a, b) {
    return (a + b);
}

/**
 * https://stackoverflow.com/questions/54857222/find-all-values-by-specific-key-in-a-deep-nested-object
 * @param obj
 * @param keyToFind
 * @returns {[string, unknown]}
 */
export function findAllByKey(obj, keyToFind) {

    // console.log('', obj);
    if (!obj) {
        // console.log('here', );
    }
    if (!obj) {
        return []
    }
    return Object.entries(obj)
        .reduce((acc, [key, value]) => (key === keyToFind)
                ? acc.concat(value)
                : (typeof value === 'object')
                    ? acc.concat(findAllByKey(value, keyToFind))
                    : acc
            , [])
}

export function fullScreen() {

    document.fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenElement ||
        document.documentElement.webkitRequestFullScreen;

    function requestFullscreen() {

        if (checkStaging()) {
            console.log('toggle fullscreen');
        }
        if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function requestMobileSafari() {
        if (isSafari && isMobile) {
            if (document.webkitFullscreenElement) {
                document.webkitCancelFullScreen();
            } else {
                const el = document.documentElement;
                el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        }
    }

    if (isSafari && isMobile) {
        requestMobileSafari();
    } else {
        if (document.fullscreenEnabled) {
            requestFullscreen();
        }
    }

    // tracking
    trackEvent(
        {
            name: TrackingLabels.names.BUTTON_CLICK,
            category: TrackingLabels.categories.UI,
            label: 'fullscreen'
        }
    )
    // end tracking
}
export function toggleGyro() {
    if (
        DeviceMotionEvent &&
        typeof DeviceMotionEvent.requestPermission === "function"
    ) {
        console.log('ask permission gyro')
        DeviceMotionEvent.requestPermission();
    }

    // tracking
    trackEvent(
        {
            name: TrackingLabels.names.BUTTON_CLICK,
            category: TrackingLabels.categories.UI,
            label: 'gyro'
        }
    )
    // end tracking
}
export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}
export function isPortrait() {
    return window.innerHeight > window.innerWidth
}
export function checkIsJSON (jsonString){
    try {
        let o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }
    return false;
};
export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function isInFolder(folder) {

    const parser = document.createElement('a');
    parser.href = window.location
    const folderName = parser.pathname.replace(/\//g, "")
    return folderName === folder
}
