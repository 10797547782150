import {siteStoreApi} from "../../store/siteStore";

export const query_videos = () =>`
  twod: entries(section: "videos2d", site: "${siteStoreApi.getState().siteHandle}") {
    ... on videos2d_default_Entry {
      id
      highBitrateHlsStream
      highBitrateMp4
      lowBitrateHlsStream
      lowBitrateMp4
      startTime
      preload
      subtitles {
        ... on subtitles_addSubtitle_BlockType {
          id
          chooseVttFile {
            url
          }
          sublanguage
        }
      }
    }
  }
  threed: entries(section: "videos360", site: "${siteStoreApi.getState().siteHandle}") {
    ... on videos360_default_Entry {
      id
      highBitrateHlsStream
      highBitrateMp4
      lowBitrateHlsStream
      lowBitrateMp4
      preload
      static360Image
    }
  }
`
