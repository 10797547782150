import "./_ui.scss"
import {debounce} from "debounce";
import UIIcon from "./components/UIIcon";
import uiStore from "./uiStore";
import themeStore from "../../../../store/themes/themeStore";
import videoStore from "../../../../store/videos/videoStore";
import {useEffect} from "react";
import {isDesktop, isIOS} from "react-device-detect";
import globalSetStore from "../../../../store/globalSets/globalSetStore";
import {experienceStore} from "../../three/SceneManager";
import audioStore from "../../../../store/audio/audioStore";
import PreviewVideo from "../../../debug/PreviewVideo/PreviewVideo";
import bt_close from "../../../../assets/icons/svg/close_dark.svg";
import {fullscreenVideoStore} from "../../three/FullScreenVideo.js/FullScreenVideo";
import modalStore, {modalStoreApi} from "../../../../store/modals/modalStore";
import {useControl, useKeyPress} from "../../../../utils/hooks";
import {fullScreenEnabled} from "../../../../utils/featuredetect";
import {STATE_HOME, STATE_PAGE, STATE_ROUTE} from "../../../../store/siteStore";
import {modalVideoStore} from "../modal/types/ModalVideo";

function UI() {

    const uiActions = uiStore(state => state.actions)
    const fullScreen = uiStore(state => state.fullScreen)
    const gyro = uiStore(state => state.gyro)
    const mute = uiStore(state => state.mute)
    const toggleMenu = uiStore(state => state.toggleMenu)
    const toggleCollectable = uiStore(state => state.toggleCollectable)
    const showMenu = uiStore(state => state.showMenu)
    const showStamp = uiStore(state => state.showStamp)
    const showMute = uiStore(state => state.showMute)
    const playing = modalVideoStore(state => state.playing)
    const videoActions = videoStore(state => state.actions)
    const play2D = videoStore(state => state.play2D)
    const theme = themeStore(state => state.theme)
    const globalSets = globalSetStore(state => state.globalSets)
    const showSkip = videoStore(state => state.showSkip)
    const is2D = experienceStore(state => state.is2D)
    const audioActions = audioStore(state => state.actions)
    const setOpen = fullscreenVideoStore(state => state.setOpen)
    const sfx = globalSetStore(state => state.sfx)
    const modalActions = modalStore(state => state.actions)



    function onMenu() {
        audioActions.playAudioByID( sfx.sfxClick )
        uiActions.toggleMenu()
    }

    function onSkip() {
        audioActions.playAudioByID( sfx.sfxClick )
        videoActions.skipVideo()
    }

    function onFullScreen() {
        audioActions.playAudioByID( sfx.sfxClick )
        uiActions.toggleFullScreen()
    }
    function onGyro() {
        audioActions.playAudioByID( sfx.sfxClick )
        uiActions.toggleGyro()
    }
    function onMute() {
        audioActions.playAudioByID( sfx.sfxClick )
        uiActions.toggleMute()
    }
    function onCollectable() {
        audioActions.playAudioByID( sfx.sfxClick )
        uiActions.setToggleCollectable(true)
    }
    function onCloseFullScreen() {
        setOpen(false)
        modalActions.setCloseModal()
    }


    function Control() {
        const keyPress = useControl(STATE_ROUTE)
        useEffect(()=> {
            if (!keyPress) return
            // console.log(`menu`, keyPress.key)
            switch (keyPress.key) {
                case "BACK":
                    onSkip()
                    break
                default:
            }
        }, [keyPress])

        const keyPress2 = useControl(STATE_PAGE)
        useEffect(()=> {
            if (!keyPress2) return
            // console.log(`menu`, keyPress2.key)
            switch (keyPress2.key) {
                case "Start":
                    onMenu()
                    break
                default:
            }
        }, [keyPress2])
    }


    return (
        <div className="ui__container">

            {
                (theme.icons.stamp.enabled && showStamp) ?
                    <div className={`ui__icon ui__${theme.icons.stamp.iconPosition}`} onClick={onCollectable}>
                        <UIIcon asset={theme.icons && theme.icons.stamp.asset} active={toggleCollectable} position={theme.icons.stamp.iconPosition}/>
                    </div>
                    : null
            }
            {
                (theme.icons.menu.enabled && !is2D && showMenu) ?
                    <div className={`sdsd ui__icon ui__${theme.icons.menu.iconPosition}`} onClick={debounce(onMenu, 500, true)}>
                        <UIIcon asset={theme.icons && theme.icons.menu.asset} active={toggleMenu} position={theme.icons.menu.iconPosition}/>
                    </div>
                    : null
            }
            {
                (theme.icons.gyro.enabled && !isDesktop) ?
                    <div className={`ui__icon ui__${theme.icons.gyro.iconPosition}`} onClick={onGyro}>
                        <UIIcon asset={theme.icons && theme.icons.gyro.asset} active={gyro} position={theme.icons.gyro.iconPosition}/>
                    </div>
                    : null
            }
            {
                (theme.icons.fullScreen.enabled && fullScreenEnabled()) ?
                    <div className={`ui__icon ui__${theme.icons.fullScreen.iconPosition}`} onClick={onFullScreen}>
                        <UIIcon asset={theme.icons && theme.icons.fullScreen.asset} active={fullScreen} position={theme.icons.fullScreen.iconPosition}/>
                    </div>
                    : null
            }
            {
                (theme.icons.sound.enabled && !(isIOS && playing)) ?
                    <div className={`ui__icon ui__${theme.icons.sound.iconPosition}`} onClick={onMute}>
                        <UIIcon asset={theme.icons && theme.icons.sound.asset} active={mute} position={theme.icons.sound.iconPosition}/>
                    </div>
                    : null

            }
            {
                theme.logo ?
                    <div className="ui__icon ui__logo">
                        <img src={theme.logo}/>
                    </div>
                    : null
            }

            {
                (play2D && showSkip) ? <div className="ui__icon ui__skip" onClick={onSkip}>
                        <img src={globalSets && globalSets.experience.skipButton}/>
                    </div>
                    : null
            }
            <div className={`ui__icon ui__tr close_fullscreen`} onClick={onCloseFullScreen}>
                <UIIcon asset={bt_close}/>
            </div>
            <div className="testtheme">
                {/*<PreviewVideo />*/}
                {/*<div style={{backgroundColor: theme.colours.primary}}/>
                <div style={{backgroundColor: theme.colours.secondary}}/>
                <div style={{backgroundColor: theme.colours.highlight}}/>*/}
                {/*<div>currentVolume: {currentVolume} </div>*/}
                {/* {
                    currentScene ?
                        <p>{currentScene.title} / {currentScene.currentState}</p>
                        : null
                }*/}
            </div>
            <Control/>
        </div>
    )
}

export default UI
