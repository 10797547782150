import React, {forwardRef, useEffect, useRef} from "react";
import "./_helpprogressspinner.scss"
import gsap from "gsap"
import DrawSVGPlugin from "../../../../../../plugins/gsap/esm/DrawSVGPlugin";
import globalSetStore from "../../../../../../store/globalSets/globalSetStore";
import modalStore from "../../../../../../store/modals/modalStore";
import themeStore from "../../../../../../store/themes/themeStore";

gsap.registerPlugin(DrawSVGPlugin);

const HelpProgressSpinner = forwardRef(({}, ref) => {

     const globalSets = globalSetStore(state => state.globalSets)
     const openModal = modalStore(state => state.openModal)
     const theme = themeStore(state => state.theme)

     useEffect(() => {
          restart()
     }, [openModal])

     function restart() {
          gsap.killTweensOf(tl.current);
          gsap.set(tl.current, {progress: 0});
          setTimeout(() => {
               gsap.to(tl.current, {
                    duration: (globalSets.experience.helpTiming / 1000) - 1,
                    progress: 1,
                    ease: "none"
               });
          }, 1000)
     }

     const tl = useRef()

     useEffect(() => {
          tl.current = gsap.timeline({paused: true});
          tl.current.fromTo(".js-countdown__progress", 1, {drawSVG: "0%", visibility: "visible"}, {
               drawSVG: "100%",
               ease: "none"
          })
     }, [])

     return (
         <div id="parent">
              <svg xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 34 34">
                   <circle cx="17" cy="17" r="15.5"
                           className="countdown__background" />

                   <circle cx="17" cy="17" r="15.5" stroke={theme.colours.highlight}
                           className="countdown__progress
                 js-countdown__progress"/>
              </svg>
         </div>
     )
});
export default HelpProgressSpinner
