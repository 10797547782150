// loading data and passing assets to other stores
// initialise things
import create from "zustand"
import {loadSpecificQuery, loadQueries, loadJson} from "../utils/getAppData"
import {videoStoreApi} from "./videos/videoStore";
import {sceneStoreApi} from "./scenes/sceneStore";
import {modalStoreApi} from "./modals/modalStore";
import {clean, removeGraphQLBlankObjects, trimEmptyObjects} from "../utils/loaders";
import {hotspotStoreApi} from "./hotspots/hotspotStore";
import {iconStoreApi} from "./icons/iconsStore";
import {audioStoreApi} from "./audio/audioStore";
import {routeStoreAPI} from "./routes/routeStore";
import {boomerangStoreAPI} from "./boomerangs/boomerangStore";
import {galleriesStoreApi} from "./galleries/galleriesStore";
import {themeStoreAPI} from "./themes/themeStore";
import {globalSetStoreAPI} from "./globalSets/globalSetStore";
import {helpStoreApi} from "./help/helpStore";
import {query_collectables} from "../graphql/queries/collectables";
import {useCollectableStoreApi} from "./collectables/collectableStore";
import {useLiveStreamStoreApi} from "./livestreams/livestreamStore";
import {buildQuery} from "../graphql/queries";
import {query_livestreams} from "../graphql/queries/livestreams";
import {errorStoreApi} from "../sitecomponents/Error";
import {checkIsJSON, isInFolder} from "../utils/utilities";
import {query_livestreams_specific} from "../graphql/queries/livestreams_specific";
import {query_savedData} from "../graphql/queries/savedData";

export const STATE_HOME = "STATE_HOME"
export const STATE_PAGE = "STATE_PAGE"
export const STATE_MODAL = "STATE_MODAL"
export const STATE_MENU = "STATE_MENU"
export const STATE_ROUTE = "STATE_ROUTE"

const [siteStore, siteStoreApi] = create((set, get) => ({
    siteData: null,
    dropCookie: false,
    siteError: false,
    siteHandle: "default",
    fontsLoaded: false,
    loading: false,
    pageState: STATE_HOME,
    actions: {
        setPageState: (v) => {
            set({pageState: v})
            // console.log('setting state', get().pageState);
        },
        setSiteData: (v) => set({siteData: v}),
        setLoaded: (v) => set({loading: v}),
        setFontsLoaded: (v) => set({fontsLoaded: true}),
        setHandle: (v) => {
            set({siteHandle: v})
            // console.log('', get().siteHandle);
            get().actions.getSiteData()
        },
        getSpecificData: async (query)=> {
            return await loadSpecificQuery(query)
        },
        getSiteData: async () => {

            let response

            if (process.env.REACT_APP_STANDALONE === "true") {
                response = await loadJson()
                response = {data: response}
            }

            else {
                let res = await siteStoreApi.getState().actions.getSpecificData(query_savedData())
                res = removeGraphQLBlankObjects(res.data.globalSets)

                /**
                 * If there is saved data and site is not in "preview" folder
                 */
                if (res[0].siteJson !== null && !isInFolder("staging") && !isInFolder("preview") && !isInFolder("cc") && !isInFolder("cc2")) {
                    console.log('%c standalone ', 'background: #fbeff0; color: #0a0929');
                    response = JSON.parse(res[0].siteJson)
                } else {
                    console.log('%c cms ', 'background: #ffcc00; color: #000000');
                    response = await loadQueries()
                }
                if (!response) {
                    errorStoreApi.getState().setError(true)
                    return
                }
                if (response.errors && response.errors.length) {
                    console.log(response.errors);
                    set(state => ({siteError: true}))
                    return
                }
            }
            console.log('', response);

            /**
             * Process Scenes
             */
            response.data.scenes = removeGraphQLBlankObjects(response.data.scenes)
            response.data.hotspots = removeGraphQLBlankObjects(response.data.hotspots)
            response.data.icons = removeGraphQLBlankObjects(response.data.icons)
            response.data.audio = removeGraphQLBlankObjects(response.data.audio)
            response.data.routes = removeGraphQLBlankObjects(response.data.routes)
            response.data.boomerangs = removeGraphQLBlankObjects(response.data.boomerangs)
            response.data.galleries = removeGraphQLBlankObjects(response.data.galleries)
            response.data.themes = removeGraphQLBlankObjects(response.data.themes)
            response.data.globalSets = removeGraphQLBlankObjects(response.data.globalSets)
            response.data.helps = removeGraphQLBlankObjects(response.data.helps)
            response.data.collectables = removeGraphQLBlankObjects(response.data.collectables)
            response.data.livestreams = removeGraphQLBlankObjects(response.data.livestreams)

            /**
             * Process scenes
             */
            sceneStoreApi.getState().actions.setAllScenes(response.data.scenes)

            /**
             * Process icons
             */
            iconStoreApi.getState().actions.setAllIcons(response.data.icons)
            /**
             * Process hotspots
             */
            hotspotStoreApi.getState().actions.setAllHotspots(response.data.hotspots)

            /**
             * Process videos
             */
            const videos = {
                twod: response.data.twod,
                threed: response.data.threed
            }
            videoStoreApi.getState().actions.setAllVideos(videos)

            /**
             * Process modals
             */
            modalStoreApi.getState().actions.setAllModals(response.data.modals)

            /**
             * Process audio
             */
            audioStoreApi.getState().actions.setAllAudios(response.data.audio)

            /**
             * Process routes
             */
            routeStoreAPI.getState().actions.setAllRoutes(response.data.routes)

            /**
             * Process routes
             */
            boomerangStoreAPI.getState().actions.setAllBoomerangs(response.data.boomerangs)

            /**
             * Process galleries
             */
            galleriesStoreApi.getState().actions.setAllGalleries(response.data.galleries)

            /**
             * Process themes
             */
            themeStoreAPI.getState().actions.setAllThemes(response.data.themes)

            /**
             * Process global sets
             */
            globalSetStoreAPI.getState().actions.setAllGlobalSets(response.data.globalSets)

            /**
             * Process helps
             */
            helpStoreApi.getState().actions.setAllHelps(response.data.helps)

            /**
             * Process collectables
             */
            useCollectableStoreApi.getState().actions.setAllCollectables(response.data.collectables)

            /**
             * Process livestreams
             */
            useLiveStreamStoreApi.getState().actions.setAllLivestreams(response.data.livestreams)

            /**
             * Preloading video
             */
            videoStoreApi.getState().actions.preloadVideos()

            set({ siteData: response })

            // if (isInFolder("preview")) console.log('response', response);

        },
        setDropCookie: (v)=> {
            set(state => ({ dropCookie: v }));
        }
    }
}))
export default siteStore
export {siteStoreApi}
