/**
 * Modal store
 *
 */
import create from "zustand";
import {trackEvent, TrackingLabels} from "../../tracking/Tracking";
import siteStore, {STATE_MODAL, STATE_PAGE} from "../siteStore";
import collectableStore from "../collectables/collectableStore";
import uiStore from "../../experience/components/html/ui/uiStore";

const [modalStore, modalStoreApi] = create((set, get) => ({
     allModalData: null,
     currentModal: null,
     closeModal: null,
     openModal: null,
     modalGroup: null,
     modalGroupIndex: 0,
     modalHideHotspots: false,
     actions: {
          /**
           * All modal data from GraphQL
           * @param modals
           */
          setAllModals(modals) {
               set(state => ({allModalData: processModals(modals)}))
               // console.log('all modals', get().allModalData);
          },
          getModalByID(id) {
               return get().allModalData.find(x => x.id === id)
          },
          setCloseModal(updateState) {

               set(state => ({closeModal: true}))
               set(state => ({modalHideHotspots: false}))
               if (updateState) {
                    setTimeout(()=> {
                         siteStore.getState().actions.setPageState(STATE_PAGE)
                    }, 200)
               }
               document.body.classList.remove("isfullscreenvideo")
               document.body.classList.remove("isModal")

               uiStore.getState().actions.setToggleCollectable(false)
          },
          // TODO: update swiper to avoid this
          initGallery(modalID) {
               let modal = get().actions.getModalByID(modalID)
          },
          setOpenModal(modalID, config = {}) {
               siteStore.getState().actions.setPageState(STATE_MODAL)
               let modal = get().actions.getModalByID(modalID)
               set(state => ({closeModal: false}))
               set(state => ({
                    openModal: {
                         config: config,
                         content: modal,
                         cache: Date.now()
                    }
               }))
               document.body.classList.add("isModal")
               if (modal.modalVideoFull) {
                    document.body.classList.add("isfullscreenvideo")
                    set(state => ({modalHideHotspots: true}))
               }

               // tracking
               const title = get().actions.getModalByID(modalID).title
                   trackEvent(
                       {
                           name: TrackingLabels.names.VIEW,
                           category: TrackingLabels.categories.MODAL,
                           label: `modal_${title}`
                       }
                   )
               // end tracking
          },
          /**
           * Used for groups of modals eg help
           * @param group
           */
          setModalGroup(group) {
               // save all modals in group
               set(state => ({
                    modalGroup: group
               }))

               set(state => ({modalGroupIndex: 0}))

               let config = {
                    isHelp: true,
                    total: group.length,
                    index: 0
               }
               this.setOpenModal(group[0].id, config)
          },
          getNextModal() {
               // console.log('get next');
               let index = get().modalGroupIndex + 1
               set(state => ({modalGroupIndex: index}))

               let config = {
                    isHelp: true,
                    total: get().modalGroup.length,
                    index: index
               }

               if (index <= get().modalGroup.length - 1) {
                    this.setOpenModal(get().modalGroup[index].id, config)

               } else {
                    this.setCloseModal()
               }

          },
          hideHotspots(v) {
               set(state => ({modalHideHotspots: v}))
          }
     }
}))

function processModals(modals) {
     let processedModals = []
     modals.forEach(modal => {
          // console.log('', modal);
          let processedModal = {}
          processedModal.id = modal.id
          processedModal.title = modal.title
          processedModal.modalAlign = modal.modalAlign
          processedModal.modalColour = modal.modalColour
          processedModal.buttonDirection = modal.buttonDirection
          processedModal.modalResponsive = modal.modalResponsive

          /**
           * Colour overrides
           */
          if (modal.modalColourOverride.length) {
     /*          if (modal.modalColourOverride[0].modalBackgroundColour) {
                    processedModal.
               }*/
               processedModal.modalColourOverride = {
                    bgColour:  modal.modalColourOverride[0].modalBackgroundColour,
                    bgOpacity: modal.modalColourOverride[0].modalBackgroundOpacity,
                    textColour: modal.modalColourOverride[0].textColour
               }
               // console.log('', processedModal);
          }

          /**
           * Modal with copy
           */
          const content = modal.modalContent.length ? modal.modalContent[0].children[0] : null
          if (content) {
               switch (content.__typename) {
                    case "modalContent_copy_BlockType":
                         processedModal.type = "content"
                         processedModal.copy = content.modalCopy
                         processedModal.buttons = content.buttons
                         // console.log('', modal);
                         break
                    case "modalContent_video_BlockType":
                         processedModal.type = "video"
                         processedModal.videoID = content.modalVideo[0].id
                         processedModal.buttons = content.buttons
                         processedModal.modalVideoFull = content.modalVideoFull
                         processedModal.modalSubCopy = content.modalSubCopy
                         processedModal.videoEvents = content.videoEvents
                         processedModal.preferHls = content.preferHls
                         break
                    case "modalContent_gallery_BlockType":
                         // console.log('', processedModal);
                         processedModal.type = "gallery"
                         processedModal.galleryID = content.modalGallery[0].id
                         processedModal.buttons = content.buttons
                         break
                    case "modalContent_dModel_BlockType":
                         // console.log('', content.modal3dFile[0].url);
                         processedModal.type = "model"
                         processedModal.modal3D = content.modal3dFile[0].url
                         processedModal.enableBackground = content.enableBackground
                         processedModal.modalSubCopy = content.modalSubCopy
                         processedModal.buttons = content.buttons
                         break
                    case "modalContent_collectable_BlockType":
                         // console.log('', content.modal3dFile[0].url);
                         processedModal.type = "collectable"
                         processedModal.collectable = content.modalCollectable[0].id
                         processedModal.modalSubCopy = content.modalSubCopy
                         processedModal.buttons = content.buttons
                         break
                    case "modalContent_iframe_BlockType":
                         processedModal.type = "iframe"
                         processedModal.iframeSrc = content.modalIframe
                         processedModal.enableBackground = content.enableBackground
                         break
                    default:
                         processedModal.type = null
               }
          } else {
               processedModal.type = null
          }

          /**
           * Modal events
           */
          if (modal.modalEvents.length) {
               processedModal.modalEvents = modal.modalEvents
          }

          processedModals.push(processedModal)
     })
     return processedModals
}

export default modalStore;
export {modalStoreApi}
