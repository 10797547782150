import {siteStoreApi} from "../../store/siteStore";

export const query_themes = () => `
  themes: entries(section: "themes", site: "${siteStoreApi.getState().siteHandle}") {
    ... on themes_default_Entry {
      iconFullscreen {
        ... on iconFullscreen_icon_BlockType {
          featureEnabled  
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconGyro {
        ... on iconGyro_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconSound {
        ... on iconSound_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconMenu {
        ... on iconMenu_iconi_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconSound {
        ... on iconSound_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconStamp {
        ... on iconStamp_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconHelp {
        ... on iconHelp_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      logo {
        url
      }
      id
      title
      borderRadius
      buttonRadius
      buttonBorder
      capitaliseModalTitles
      capitaliseButtons
      modalTitleColour
      modalTitleColourDark
      modalTitleFontSize
      iconsOpacity
      bodyFont {
        id
        url
      }
      bodyTextSize
      boldFont {
        id
        url
      }
      extraBoldFont {
        id
        url
      }
      colourPrimary {
        ... on colourPrimary_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      colourSecondary {
        ... on colourSecondary_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      colourHighlight {
        ... on colourHighlight_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      colourBodyOverride {
        ... on colourBodyOverride_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      landingPageColour {
        ... on landingPageColour_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      landingPageOverride {
        ... on landingPageOverride_mobile_BlockType {
          overrideProperties {
            ... on overrideProperties_overrideProperties_BlockType {
              m_maxwidth: maxWidth
              m_width: width
              m_buttontransform: transformY
              m_buttonscale: buttonScale
              m_ccfontsize: cookieconsentFontSize
            }
          }
        }
        ... on landingPageOverride_desktop_BlockType {
          overrideProperties {
            ... on overrideProperties_overrideProperties_BlockType {
              d_maxwidth: maxWidth
              d_width: width
              d_buttontransform: transformY
              d_buttonscale: buttonScale
              d_ccfontsize: cookieconsentFontSize
            }
          }
        }
      }
    }
  }
`
