import {memo, Suspense, useEffect, useRef} from "react"
import {
     Bloom,
     DepthOfField,
     EffectComposer, Glitch, Noise, Pixelation, Vignette, SMAA
} from '@react-three/postprocessing'
import {GlitchMode} from 'postprocessing'
import {isDesktop, isMobile, isTablet} from 'react-device-detect';
import create from "zustand";
import gsap from "gsap";
import modalStore from "../../../../store/modals/modalStore";
import {useAnimationFrame} from "../../../../utils/hooks";
import globalSetStore, {globalSetStoreAPI} from "../../../../store/globalSets/globalSetStore";
import {map} from "../../../../utils/utilities";


export const postStore = create((set, get) => ({
     blur: 20,
     showPost: true,
     glitch: false,
     pixel: false,
     doEffect: false,
     setBlur: (v) => set({blur: v}),
     setShowPost: (v) => set({showPost: v}),
     setGlitch: (v) => set({glitch: v}),
     setPixel: (v) => set({pixel: v}),
     setDoEffect: (v) => {
          set({doEffect: {value: v, cb: Date.now()}})
     }
}))

function Postprocessing() {
     const showPost = postStore(state => state.showPost)
     const setShowPost = postStore(state => state.setShowPost)
     const openModal = modalStore(state => state.openModal)
     const closeModal = modalStore(state => state.closeModal)
     const blur = postStore(state => state.blur)
     const setBlur = postStore(state => state.setBlur)
     const glitch = postStore(state => state.glitch)
     const pixel = postStore(state => state.pixel)
     const setGlitch = postStore(state => state.setGlitch)
     const setPixel = postStore(state => state.setPixel)
     const doEffect = postStore(state => state.doEffect)
     const setDoEffect = postStore(state => state.setDoEffect)
     const effects = globalSetStore.getState().globalSets.experience.postprocessing

     const blurRef = useRef()
     const o = useRef({v: 100})

     const fr = useRef(true);
     useEffect(() => {
          if (fr.current === true) {
               fr.current = false;
               return;
          }
          if (doEffect.value) {

               switch (globalSetStore.getState().globalSets.experience.transitionEffect) {
                    case "glitch":
                         setGlitch(true)
                         setTimeout(() => {
                              setDoEffect(false)
                         }, 300)
                         break;
                    case "pixelate":
                         setPixel(true)
                         setTimeout(() => {
                              setDoEffect(false)
                         }, 800)
                         break;
                    default:
               }
          } else {
               switch (globalSetStore.getState().globalSets.experience.transitionEffect) {
                    case "glitch":
                         setGlitch(false)
                         break;
                    case "pixelate":
                         setPixel(false)
                         break;
                    default:
               }
          }
     }, [doEffect])

     useEffect(() => {
          if (pixel) {
               const pixelVal = {v: 50}
               gsap.killTweensOf(pixelVal)
               gsap.to(pixelVal, {
                    duration: 0.8, ease: "quad.out", v: 0, onUpdate: () => {
                         setBlur(pixelVal.v)
                    },
                    onComplete: () => {
                         setDoEffect(false)
                    }
               })
          }
     }, [pixel])

     useEffect(() => {

          if (openModal) {
               setShowPost(true)
          }
          if (closeModal) {
               setShowPost(false)
          }
     }, [openModal, closeModal])


     useEffect(() => {

          gsap.killTweensOf(o.current)
          if (isDesktop) {
               /*gsap.to(o.current, {
                   duration: showPost ? 1.5: 0, ease: "sine.out", v: showPost ? 3.3 : 0, onUpdate: () => {
                       setBlur(o.current.v)
                   }
               })*/
          } else {
               if (isTablet) {
                    setBlur(o.current.v)
               }
          }

     }, [showPost])


     return (
         <Suspense fallback={null}>
              <EffectComposer>

                   {
                        pixel ?
                            <>
                                 <Pixelation
                                     granularity={blur} // pixel granularity
                                 />
                                 <Glitch
                                     delay={[0.603, 1.971]} // min and max glitch delay
                                     duration={[0.1, 0.2]} // min and max glitch duration
                                     strength={[0.015, 0.02]} // min and max glitch strength
                                     columns={0.1}
                                     mode={GlitchMode.SPORADIC} // glitch mode
                                     active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
                                     ratio={0.8} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
                                 />
                            </>
                            : null
                   }
                   {
                        glitch ?
                            <Glitch
                                delay={[0.603, 2.971]} // min and max glitch delay
                                duration={[0.2, 1]} // min and max glitch duration
                                strength={[0.3, 0.6]} // min and max glitch strength
                                columns={0.1}
                                mode={GlitchMode.CONSTANT_MILD} // glitch mode
                                active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
                                ratio={0.8} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
                            />
                            : null
                   }

                   {/*<DOF />*/}
                   {
                        effects.includes("vignette") ?
                            <Vignette eskil={false} offset={0.1} darkness={0.8}/>
                            : null
                   }
                   {
                        effects.includes("bloom") ?
                            <Bloom luminanceThreshold={0.59} luminanceSmoothing={0.9} height={300}/>
                            : null
                   }
                   {
                        effects.includes("noise") ?
                            <Noise opacity={0.05}/>
                            : null
                   }
                   {/*<SMAA />*/}
                   {/*<DepthOfField focusDistance={0.1} focalLength={0.12} bokehScale={4} height={480} />*/}
                   {/*<DepthOfField focusDistance={0.8} focalLength={0.6} bokehScale={1} height={400} ref={blurRef}/>*/}
              </EffectComposer>
         </Suspense>
     )
}

export default memo(Postprocessing)
