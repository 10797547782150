import {ShaderMaterial, TextureLoader, sRGBEncoding} from "three"
import {useEffect, useMemo, useRef, useState} from "react"
import {useFrame, useThree} from "@react-three/fiber"
import * as THREE from 'three'
import {useKeyPress} from "../../../utils/hooks";
import create from "zustand";
import gsap from "gsap";
let aspect = 1.77778


export const [transitionStore, transitionStoreApi] = create((set, get) => ({
    transition: false,
    showTransition: (v) => set({transition: v})
}))



function TransitionPlane() {
     const dummyTexture = new TextureLoader().load('blank.png')
     const transition = transitionStore(state => state.transition)
     const {camera, viewport} = useThree()
     const planeRef = useRef()
     const thisRef = useRef()
     const [texture] = useState(dummyTexture)
     useEffect(()=> {
          if (!texture) return;
          texture.encoding = sRGBEncoding;
     }, [texture])

     useEffect(()=> {
          // console.log('transition', transition);
          if (transition) {
               // addScreenshot()
          } else {
               // console.log('OUT');
          }
     }, [transition])

     useKeyPress("t", ()=> {
          shaderMaterial.transparent = true
          gsap.to(shaderMaterial.uniforms.u_Opacity, {duration: 1, value: 0})
     })

     useFrame(() => {
          // aspect = 1.2
          thisRef.current.quaternion.copy(camera.quaternion)
          resize();
     })

     function addScreenshot() {
          const canvas = document.querySelector('canvas');
          const screenshot = canvas.toDataURL("image/png", 1.0);
          const screenshotTexture = new TextureLoader().load(screenshot, (texture)=> {
               console.log('loaded', texture);
               texture.encoding = THREE.sRGBEncoding
               texture.format = THREE.RGBAFormat;
               shaderMaterial.uniforms.u_Txt1.value = texture;
               setTimeout(()=> {
                    thisRef.current.visible = true
                    gsap.to(shaderMaterial.uniforms.u_Opacity, {duration: 0, value: 1})
               }, 0)

          })
     }



     function resize() {

          aspect = window.innerWidth / window.innerHeight
          const scale = 1
          // if (!texture) return
          if ((viewport.width / aspect) < viewport.height) {
               planeRef.current.scale.set(
                   (viewport.height/scale) * aspect,
                   (viewport.height/scale),
                   0.11
               )
          } else {
               planeRef.current.scale.set(
                   (viewport.width/scale),
                   (viewport.width/scale) / aspect,
                   0.1
               )
          }
     }

     const shaderMaterial = useMemo(
         () =>
             new ShaderMaterial({
                  transparent: true,
                  side: THREE.FrontSide,
                  fragmentShader: `
                uniform sampler2D u_Txt1;
                uniform float u_Opacity; 
                varying vec2 vUv;
                void main() {
                  gl_FragColor = ( texture2D(u_Txt1, vUv) );
                  gl_FragColor = vec4(gl_FragColor.rgb, u_Opacity);
                  #include <encodings_fragment>
                }`,
                  uniforms: {
                       u_Txt1: { value: texture },
                       u_Opacity: {type: "f", value: 1.0},
                  },
                  vertexShader: `#include <common>
                varying vec2 vUv;
                void main () {
                  vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`
             }),
         [texture]
     );
     shaderMaterial.map = texture
     shaderMaterial.map.value = texture

     return (
         <group ref={thisRef} position={[0, 0, 0.01]} visible={false}>
              <mesh ref={planeRef} material={shaderMaterial}>
                   {/*<meshStandardMaterial color='red' side={THREE.DoubleSide} opacity={0.5} transparent/>*/}
                   <boxBufferGeometry attach="geometry" args={
                        [
                             1,
                             1,
                             0.1
                        ]
                   }/>
              </mesh>
         </group>
     )
}

export default TransitionPlane
