/**
 * Used for routes and boomerangs. FullscreenVideo.js is for livestreams etc
 */
import {ShaderMaterial, TextureLoader, sRGBEncoding, Vector2, FramebufferTexture, RGBAFormat, DoubleSide} from "three"
import React, {useEffect, useMemo, useRef, useState} from "react"
import videoStore, {videoStoreApi} from "../../../../store/videos/videoStore"
import {useFrame, useThree} from "@react-three/fiber"
import gsap from "gsap"
import create from "zustand"
import {useKeyPress} from "../../../../utils/hooks";
import * as THREE from "three";
import {postStore} from "../effects/Postprocessing";
import {CONNECTION_STATIC, preloadStore} from "../../../../sitecomponents/Preloader";
import {isIOS} from "react-device-detect";

const [video2DStore, video2DApi] = create((set, get) => ({
    opacity: 0.6,
    texture: null,
    setOpacity: (v) => set({opacity: v}),
    setTexture: (v) => set({texture: v})
}))

function Video2D() {
    const play2D = videoStore(state => state.play2D)
    const videoPlaying = videoStore(state => state.videoPlaying)
    const texture = video2DStore(state => state.texture)
    const setTexture = video2DStore(state => state.setTexture)

    const {camera, viewport} = useThree()
    const planeRef = useRef()
    const thisRef = useRef()
    const matRef = useRef()
    const dpr = window.devicePixelRatio;

    useEffect(()=> {
        if (videoPlaying && !play2D) {
            matRef.current.transparent = true
            matRef.current.opacity = 0
            planeRef.current.visible = false
        }
    }, [videoPlaying])


    function fade(fadeIn) {
        matRef.current.transparent = true
        const o = {v: fadeIn ? 0 : 1}
        gsap.to(o, {duration: 0.5, v: fadeIn ? 1 : 0,
            onUpdate: ()=> {
                matRef.current.opacity = o.v
            },
            onComplete: ()=> {
                matRef.current.transparent = false
            }
        })
    }

    useEffect(()=> {
        if (videoPlaying && play2D) {
            // console.log('play 2d', play2D);
            /*let t = videoStoreApi.getState().actions.getTexture()
            setTexture(t)*/
            fade(true)
            planeRef.current.visible = true
        }
        /*if (videoPlaying && !play2D) {
            matRef.current.transparent = true
            matRef.current.opacity = 0
        }*/
    }, [videoPlaying])

    useFrame(() => {
        thisRef.current.quaternion.copy(camera.quaternion)
        resize();
    })
    function resize(v = 0.1) {
        const aspect = 1.77778;
        if (!texture) return
        if ((viewport.width / aspect) < viewport.height) {
            planeRef.current.scale.set(
                viewport.height * aspect,
                viewport.height,
                0.11
            )
        } else {
            planeRef.current.scale.set(
                viewport.width,
                viewport.width / 1.7778,
                0.1
            )
        }
    }

    function getVideo() {
        const vid = document.getElementById(`newvid1`)
        // const vid = document.createElement("video");
        /*vid.classList.add("previewVideo")
        vid.crossOrigin = "Anonymous"
        vid.playsInline = true
        vid.muted = true
        vid.loop = true*/
        // vid.src = "blank-0.2-sec.m4v"
        let tex = new THREE.VideoTexture(vid)
        setTexture(tex)
        // vid.play()
    }
/*    function getVideo() {
        const vid = document.createElement("video");
        vid.classList.add("previewVideo")
        vid.crossOrigin = "Anonymous"
        vid.playsInline = true
        vid.muted = true
        vid.loop = true
        vid.src = "blank-0.2-sec.m4v"
        let tex = new THREE.VideoTexture(vid)
        setTexture(tex)
        // vid.play()
    }*/

    useEffect(()=> {
        if (preloadStore.getState().connectionSpeed !== CONNECTION_STATIC)
        {
            getVideo()
        } else {

        }
    }, [])


    return (
        <group ref={thisRef} position={[0, 0, 0]} visible={isIOS ? play2D : true}>
            <mesh ref={planeRef}>
                <meshBasicMaterial attach="material" map={texture} ref={matRef} opacity={0} transparent={true}/>
                <boxBufferGeometry attach="geometry" args={
                    [
                        1,
                        1,
                        0.1
                    ]
                }/>
            </mesh>


            {/*<mesh ref={planeRef}>
                <meshBasicMaterial attach="material" map={texture} ref={matRef} opacity={1} transparent/>
                <boxBufferGeometry attach="geometry" args={
                    [
                        0.5,
                        0.5,
                        0.1
                    ]
                }/>
            </mesh>*/}
        </group>
    )
}

export default Video2D
