import create from "zustand";
import {fullScreen, toggleGyro} from "../../../../utils/utilities";
import {audioStoreApi} from "../../../../store/audio/audioStore";
import {modalTypeStoreApi} from "../modal/Modal";
import {globalSetStoreAPI} from "../../../../store/globalSets/globalSetStore";
import {modalStoreApi} from "../../../../store/modals/modalStore";
import {trackEvent, TrackingLabels} from "../../../../tracking/Tracking";
import collectableStore from "../../../../store/collectables/collectableStore";
import siteStore, {STATE_MENU, STATE_PAGE} from "../../../../store/siteStore";

const [uiStore, uiStoreApi] = create((set, get) => ({
    toggleMenu: false,
    fullScreen: false,
    gyro: false,
    mute: false,
    toggleCollectable: false,
    showMenu: false,
    showStamp: false,
    showMute: true,
    actions: {
        toggleMenu() {

            set(state => ({ toggleMenu: !get().toggleMenu }))
            // console.log('toggle', get().toggleMenu);

            if (get().toggleMenu) {
                siteStore.getState().actions.setPageState(STATE_MENU)
            } else {
                siteStore.getState().actions.setPageState(STATE_PAGE)
            }
            // tracking
            if (get().toggleMenu) {

                trackEvent(
                    {
                        name: TrackingLabels.names.OPEN,
                        category: TrackingLabels.categories.UI,
                        label: 'menu'
                    }
                )
            }
            // end tracking
        },
        setMenu(v) {
            // console.log('setMenu', get().toggleMenu);
            // set(state => ({ toggleMenu: v }))
        },
        toggleFullScreen() {
            set(state => ({ fullScreen: !get().fullScreen }))
            fullScreen()
        },
        setFullScreen(v) {
            set(state => ({ fullScreen: v }))
        },
        toggleGyro() {
            set(state => ({ gyro: !get().gyro }))
            toggleGyro()
        },
        setGyro(v) {
            set(state => ({ gyro: v }))
        },
        toggleMute() {
            set(state => ({ mute: !get().mute }))
            audioStoreApi.getState().actions.toggleMute(!get().mute)

            // tracking
            trackEvent(
                {
                    name: TrackingLabels.names.BUTTON_CLICK,
                    category: TrackingLabels.categories.UI,
                    label: 'sound-toggle'
                }
            )
            // end tracking
        },
        setMute(v) {
            set(state => ({ mute: v }))
            audioStoreApi.getState().actions.setGlobalVolume(0)
        },
        // send ID to open modal, false to toggle icon active
        setToggleCollectable(v) {
            set(state => ({ toggleCollectable: v }))
            if (v) {
                const complete = collectableStore.getState().complete

                if (complete) {
                    modalStoreApi.getState().actions.setOpenModal(
                        collectableStore.getState().completeModal
                    )
                } else {
                    modalStoreApi.getState().actions.setOpenModal(
                        globalSetStoreAPI.getState().globalSets.experience.primaryCollectable.id
                    )
                }

                // tracking
                trackEvent(
                    {
                        name: TrackingLabels.names.SHOW,
                        category: TrackingLabels.categories.UI,
                        label: 'stamp_card'
                    }
                )
                // end tracking
            }
        },
        toggleMenuIcon(v) {
            set(state => ({ showMenu: v }))
        },
        toggleStampIcon(v) {
            set(state => ({ showStamp: v }))
        },
        toggleMuteIcon(v) {
            set(state => ({ showMute: v }))
        },
    }
}))

export default uiStore
export {uiStoreApi}
