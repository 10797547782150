import {ShaderMaterial, TextureLoader, sRGBEncoding} from "three"
import {useEffect, useMemo, useRef, useState} from "react"
import {useFrame, useThree} from "@react-three/fiber"
import * as THREE from 'three'
import gsap from "gsap"
import create from "zustand"
import {useKeyPress} from "../../../../utils/hooks";
import {videoStoreApi} from "../../../../store/videos/videoStore";


export const [fullscreenVideoStore, fullscreenVideoStoreApi] = create((set, get) => ({
    open: false,
    opacity: 0.6,
    videoTexture: null,
    setOpen: (v) => {
        if (v) {
            document.body.classList.add("isfullscreenvideo")
            document.body.classList.add("removebg")
            videoStoreApi.getState().actions.setPlay2D(true)
        } else {
            document.body.classList.remove("isfullscreenvideo")
            document.body.classList.remove("removebg")
            videoStoreApi.getState().actions.setPlay2D(false)
        }

        set({open: v})
    },
    setOpacity: (v) => set({opacity: v}),
    setTexture: (v) => set({videoTexture: v})
}))

function FullScreenVideo() {
    const dummyTexture = new TextureLoader().load('blank.png')
    const videoTexture = fullscreenVideoStore(state => state.videoTexture)
    const open = fullscreenVideoStore(state => state.open)
    const {camera, viewport} = useThree()
    const planeRef = useRef()
    const thisRef = useRef()
    const [texture] = useState(dummyTexture)
    useEffect(()=> {
        if (!texture) return;
        texture.encoding = sRGBEncoding;
    }, [texture])

    useEffect(()=> {
        if (!videoTexture) return
        // console.log('videoTexture', videoTexture);
        shaderMaterial.uniforms.u_Txt1.value = videoTexture;
    }, [videoTexture])

    useKeyPress("b", ()=> {

    })

    useFrame(() => {
        thisRef.current.quaternion.copy(camera.quaternion)
        resize();
    })
    function resize(v = 0.1) {
        const aspect = 1.77778;
        if (!texture) return
        if ((viewport.width / aspect) < viewport.height) {
            planeRef.current.scale.set(
                viewport.height * aspect,
                viewport.height,
                0.11
            )
        } else {
            planeRef.current.scale.set(
                viewport.width,
                viewport.width / 1.7778,
                0.1
            )
        }
    }

    const shaderMaterial = useMemo(
        () =>
            new ShaderMaterial({
                transparent: true,
                side: THREE.FrontSide,
                fragmentShader: `
                uniform sampler2D u_Txt1;
                uniform float u_Opacity; 
                varying vec2 vUv;
                void main() {
                  gl_FragColor = ( texture2D(u_Txt1, vUv) );
                  gl_FragColor = vec4(gl_FragColor.rgb, u_Opacity);
                  #include <encodings_fragment>
                }`,
                uniforms: {
                    u_Txt1: { value: texture },
                    u_Opacity: {type: "f", value: 1.0},
                },
                vertexShader: `#include <common>
                varying vec2 vUv;
                void main () {
                  vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`
            }),
        [texture]
    );
    shaderMaterial.map = texture
    shaderMaterial.map.value = texture

    return (
        <group ref={thisRef} position={[0, 0, 0]} visible={open}>
            <mesh ref={planeRef} material={shaderMaterial}>
                {/*<meshStandardMaterial color='red' side={THREE.DoubleSide} opacity={0.5} transparent/>*/}
                <boxBufferGeometry attach="geometry" args={
                    [
                        1,
                        1,
                        0.1
                    ]
                }/>
            </mesh>
        </group>
    )
}

export default FullScreenVideo
