import {DoubleSide} from "three";
import {useRef, useEffect} from "react";
import {Canvas, useLoader, useThree} from "@react-three/fiber";
import {useKeyPress} from "../../../utils/hooks";
import create from "zustand";
import videoStore, {videoStoreApi} from "../../../store/videos/videoStore";
import * as THREE from "three";


const testvideo2DStore = create((set, get) => ({
    opacity: 0.6,
    texture: null,
    setOpacity: (v) => set({opacity: v}),
    setTexture: (v) => set({texture: v})
}))
function Test2D() {

    const pref = useRef()
    const texture = testvideo2DStore(state => state.texture)
    const setTexture = testvideo2DStore(state => state.setTexture)
    const videoPlaying = videoStore(state => state.videoPlaying)

    const tt = useLoader(THREE.TextureLoader,"https://lucozade2330.immersivevideoplayer.com/videos/The_Hub_New_Position_hannah.jpg")
    useKeyPress("b", ()=> {
        let t = videoStoreApi.getState().actions.getTexture()


        setTexture(t)
        console.log(`sdfsdf`)
    })

    useEffect(()=> {
       // let t = videoStoreApi.getState().actions.getTexture()
      //  setTexture(t)
    }, [videoPlaying])

    useEffect(()=> {
/*        const vid = document.getElementById(`newvid2`)
/!*        vid.src = "https://lucozade2330.immersivevideoplayer.com/videos/lucozade2330/final-renders/v2/Vault_To_Hub_Transition_1080_5mbps.mp4"*!/
        let tex = new THREE.VideoTexture(vid)
        setTexture(tex)*/

        let t = videoStoreApi.getState().actions.getTexture()
        setTexture(t)
    }, [])

    return (
        <group position={[0, 0, 4]}>
            <mesh ref={pref}>
                <planeBufferGeometry attach="geometry" args={[3, 3]} />
                <meshBasicMaterial attach="material" side={DoubleSide} transparent={false} map={texture}/>
            </mesh>
        </group>
    )
}
export default Test2D