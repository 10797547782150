import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";
export const query_modals = () =>`
  modals: entries(section: "modals", site: "${siteStoreApi.getState().siteHandle}") {
    ... on modals_default_Entry {
      id
      title
      modalAlign
      buttonDirection
      modalColour
      modalResponsive
      modalColourOverride {
        ... on modalColourOverride_customiseColours_BlockType {
          modalBackgroundColour
          modalBackgroundOpacity
          textColour
        }
      }
      modalEvents {
        ... on modalEvents_modalEvent_BlockType {
          children {
            ... on modalEvents_event_BlockType {
              modalEventsDropdown
              children {
                ... on modalEvents_action_BlockType {
                  ${query_actions}                   
                }
              }
            }
          }
        }
      }   
      modalContent {
        ... on modalContent_mcontent_BlockType {
          children {
            ... on modalContent_copy_BlockType {
              __typename
              modalCopy
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_iframe_BlockType {
                __typename
                enableBackgroundiFrame: modal3dBackground
                modalIframe
            }
            ... on modalContent_video_BlockType {
              __typename
              modalVideo {
                ... on videos2d_default_Entry {
                  id
                }
              }
              modalVideoFull
              modalSubCopy
              preferHls
              videoEvents: children {
                ... on modalContent_videoEvents_BlockType {
                    modalVideoEvents
                  ${query_actions} 
                }
              }
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_gallery_BlockType {
              __typename
              modalGallery {
                ... on modalGallery_default_Entry {
                  id
                }
              }
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_dModel_BlockType {
              __typename
              enableBackground: modal3dBackground
              modal3dFile {
                url
              }
              modalSubCopy
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
            ... on modalContent_collectable_BlockType {
              __typename        
              modalCollectable {
                id
              }
              buttons: children {
                ... on modalContent_modalButton_BlockType {
                  button
                  buttonType
                  ${query_actions} 
                }
              }
            }
          }
        }
      }
    }
  }
`
