/**
 * Scene store
 *
 */
import create from "zustand";
import {videoStoreApi} from "../videos/videoStore";
import {findAllByKey, getValueByKey} from "../../utils/utilities";
import {audioStoreApi} from "../audio/audioStore";
import {uiStoreApi} from "../../experience/components/html/ui/uiStore";
import {doActions} from "../actionBuilderProcessing";
import {controlsStoreApi} from "../../experience/components/three/controls/CControls";
import {trackEvent, TrackingLabels} from "../../tracking/Tracking";
import {CONNECTION_STATIC, preloadStore} from "../../sitecomponents/Preloader";

const [sceneStore, sceneStoreApi] = create((set, get) => ({
     allScenes: null,
     menuScenes: null,
     sceneHotspots: [],
     currentScene: null,
     currentState: null,
     actions: {
          setHotspotIndex(index) {
               set(state => ({hotspotIndex: index}))
          },
          setHotspotDirection(forward) {
               let index
               if (forward) {
                    if (get().hotspotIndex < get().sceneHotspots.length-1) {
                         index = get().hotspotIndex+1
                    } else {
                         index = 0
                    }
               } else {
                    if (get().hotspotIndex === 0 || isNaN(get().hotspotIndex)) {
                         index = get().sceneHotspots.length-1
                    } else {
                         index = get().hotspotIndex-1
                    }
               }
               // console.log('', index);
               set(state => ({hotspotIndex: index}))
          },
          setHotspotActivate() {
               set(state => ({hotspotActivate: Date.now()}))
               setTimeout(()=> {
                    set(state => ({hotspotActivate: null}))
               }, 60)
          },
          /**
           * All scene data from GraphQL
           * @param scenes
           * scenes can stay the same
           * actions need processing
           */
          setAllScenes(scenes) {
               set(state => ({allScenes: processScenes(scenes)}))
               let _menuScenes = []
               get().allScenes.forEach((scene, index)=> {

                    if (scene.sceneMap) {
                         _menuScenes.push(scene)
                    }
               })
               // console.log(_menuScenes)
               set(state => ({menuScenes: _menuScenes}))
               /**
                * Set the initial properties of the scene
                * some values can be overwritten on state changes
                */
          },
          /**
           * @param id
           * @returns scene data
           */
          getScene(id) {
               return get().allScenes.find(x => x.id === id)
          },
          getAllScenes() {
               return get().allScenes
          },
          /**
           * Change the current scene
           * @param id
           * @param active
           */
          setMapVisibility(id, active) {
               const selectedScene = get().actions.getScene(id)
               // console.log('setMapVisibility', selectedScene)
               selectedScene.sceneMap = active
          },
          setScene(id, moveCam=true) {
               // console.log('id', get().allScenes);
               const selectedScene = get().actions.getScene(id)

               // tracking
               trackEvent(
                   {
                        name: TrackingLabels.names.LOAD,
                        category: TrackingLabels.categories.SCENE,
                        label: `scene_${selectedScene.title}`
                   }
               )
               // end tracking

               set(state => ({currentScene: selectedScene}))
               /**
                * Change the 360 video
                */




               /**
                * Check to see whether to play a boomerang
                */
               const events = getSceneState(selectedScene).events
               // console.log('events', events);

               videoStoreApi.getState().actions.play360(
                   selectedScene.current.current360,
                   true
               )
               /*if (preloadStore.getState().connectionSpeed === CONNECTION_STATIC) {

               } else {
                    videoStoreApi.getState().actions.play360(
                        selectedScene.current.current360,
                        true
                    )
               }*/

               /* if (findAllByKey(events, "boomerangID").length) {
                     videoStoreApi.getState().actions.play360(
                         selectedScene.current.current360,
                         false
                     )
                } else {
                     videoStoreApi.getState().actions.play360(
                         selectedScene.current.current360,
                         true
                     )
                }*/

               /**
                * State events
                * also in setSceneState
                */

               /**
                * Check if videoElement has started playing
                * then add hotspots to scene
                */
               // console.log('', getSceneState(selectedScene).sceneStateHotspots);
               set(state => ({
                    sceneHotspots: []
               }))

               const initScene = (cameraMove = false)=> {
                    setTimeout(()=> {
                         set(state => ({
                              sceneHotspots: getSceneState(selectedScene).sceneStateHotspots
                         }))
                    }, 100)

                    doOpenEvent(getSceneState(selectedScene))
                    // camera focus
                    if (getSceneState(selectedScene).cameraOrientation) {
                         console.log('move cam from scene', getSceneState(selectedScene).cameraOrientation)
                         controlsStoreApi.getState().setTarget(getSceneState(selectedScene).cameraOrientation, false)
                    }
               }

               if (preloadStore.getState().connectionSpeed === CONNECTION_STATIC) {
                    initScene(true)
               } else {
                    syncFader().then(() => {
                         initScene()
                    })
               }



               /**
                * Audio
                */
               if (selectedScene.current.currentAudio) {
                    audioStoreApi.getState().actions.playAudioByID(selectedScene.current.currentAudio)
               } else {
                    // console.log('stop audio');
                    audioStoreApi.getState().actions.stopCurrentAudio()
               }
               // audioStoreApi.getState().actions.playAudioByID(selectedScene.current.currentAudio)
               uiStoreApi.getState().actions.setMenu(false)

          },
          /**
           * Change the state of the current scene
           */
          setSceneState(scene, state) {
               console.log('setSceneState', scene, state);
               // console.log('currentScene', get().currentScene.id);
               let targetScene = get().actions.getScene(scene)


               targetScene.currentState = state;

               // video


               if (getSceneState(targetScene).scene360) {
                    // console.log('change', targetScene.current.current360, getSceneState(targetScene).scene360);
                    targetScene.current.current360 = getSceneState(targetScene).scene360
                    if (scene === get().currentScene.id) {
                         videoStoreApi.getState().actions.play360(
                             getSceneState(targetScene).scene360
                         )
                    }
               } else {
                    // console.log('no update to 360');
               }


               // tracking
               let _currentScene = get().actions.getScene(get().currentScene.id).title
               let _targetState = getSceneState(targetScene).sceneHandle
               trackEvent(
                   {
                        name: TrackingLabels.names.SET_STATE,
                        category: TrackingLabels.categories.SCENE,
                        label: `scene_${_currentScene}_${_targetState}`
                   }
               )
               // end tracking

               if (scene !== get().currentScene.id) {
                    // setting other scene state
                    // do not apply state changes to current scene
                    return
               }
               /**
                * Events
                */
               // console.log('doing event set state',);
               doOpenEvent(getSceneState(targetScene))
               /**
                * hotspots
                */
               set(state => ({
                    sceneHotspots: []
               }))
               console.log('', getSceneState(targetScene).sceneStateHotspots);
               setTimeout(() => {
                    console.log('setstate hotspots');
                    set(state => ({
                         sceneHotspots: getSceneState(targetScene).sceneStateHotspots
                    }))
               }, 500)

               set(state => ({currentState: Date.now()}))
               /**
                * Update scene properties
                */

               // audio
               if (getSceneState(targetScene).sceneAudio) {
                    console.log('audio', getSceneState(targetScene).sceneAudio);
                    audioStoreApi.getState().actions.playAudioByID(getSceneState(targetScene).sceneAudio)
                    targetScene.current.sceneAudio = getSceneState(targetScene).sceneAudio
               } else {
                    console.log('no update to audio');
               }

               // camera focus
               if (getSceneState(targetScene).cameraOrientation) {
                    console.log('STATE', getSceneState(targetScene).cameraOrientation);
                    controlsStoreApi.getState().setTarget(getSceneState(targetScene).cameraOrientation)
               }
          },
          endBoomerang() {
               videoStoreApi.getState().actions.play360(
                   get().currentScene.current.current360
               )
               // console.log('', get().currentScene.current.current360);
          },
          getHelp() {
               // called from helpStore
               // check if help is available for this state
               let help = getSceneState(get().currentScene).help;
               return (help)
          },
          doVideoRestart() {
               // console.log('doVideoRestart', );
               // TODO
               try {
                    let state = getSceneState(get().currentScene)
                    if (state.events.EVENT_360_END) {
                         doEnd360Event(state.events.EVENT_360_END)
                    }
               } catch (e) {
                    console.log('', e);
               }

          }
     }
}))
export default sceneStore;
export {sceneStoreApi}

async function syncFader() {
     let currentVideo = videoStoreApi.getState().videoElement
     while (currentVideo.currentTime <= 0.02) {
          await new Promise(resolve => {
               requestAnimationFrame(resolve);
          })
     }
}


function processScenes(scenes) {
     let allProcessed = []

     scenes.forEach(scene => {
          let processedScene = {}
          processedScene.current = {}
          processedScene.id = scene.id
          processedScene.title = scene.title
          processedScene.sceneMap = scene.sceneMap
          processedScene.sceneDescription = scene.sceneDescription
          processedScene.states = []
          scene.sceneStates.forEach(state => {
               let processedState = {}
               processedState.id = state.id
               processedState.sceneDescription = state.sceneDescription && state.sceneDescription
               processedState.scene360 = state.scene360[0] && state.scene360[0].id
               processedState.sceneAudio = state.sceneAudio[0] && state.sceneAudio[0].id
               processedState.sceneHandle = state.sceneHandle
               processedState.sceneStateHotspots = state.sceneStateHotspots
               processedState.events = processEvents(state.events)
               // console.log('processedState.events', processedState.events);
               processedState.help = state.stateHelp[0] && state.stateHelp[0].id
               // processedState.cameraOrientation = state.cameraOrientation ? state.cameraOrientation[0] : null
               if (state.cameraOrientation && state.cameraOrientation[0].x) {
                    processedState.cameraOrientation = state.cameraOrientation[0]
               } else {
                    processedState.cameraOrientation = {x: 0, y: 0, z: 0}
               }

               // Add videos to preload
               if (processedState.scene360) {
                    videoStoreApi.getState().actions.addActiveVideo(processedState.scene360)
               }


               processedScene.states.push(processedState)
          })
          processedScene.currentState = scene.sceneStates[0].sceneHandle

          /**
           * set initial properties of scene.current
           */
          processedScene.current.current360 = scene.sceneStates[0].scene360.length && scene.sceneStates[0].scene360[0].id
          if (scene.sceneStates[0].sceneAudio.length) {
               processedScene.current.currentAudio = scene.sceneStates[0].sceneAudio[0].id
          }
          allProcessed.push(processedScene)
     })
     return allProcessed;
}

function processEvents(events) {
     // console.log('', events);
     let processedEvents = {}
     events.forEach(event => {
          switch (event.event) {
               case "stateOpen":
                    processedEvents.EVENT_OPEN = event.eventActions[0]
                    break
               case "stateOpenFirst":
                    processedEvents.EVENT_OPEN_FIRST = event.eventActions[0]
                    break
               case "on360End":
                    processedEvents.EVENT_360_END = event.eventActions[0]
                    break
               default:
          }
     })
     return processedEvents
}

function getSceneState(selectedScene) {
     return getValueByKey(selectedScene?.states, "sceneHandle", selectedScene.currentState)
}

function doOpenEvent(state) {
     if (state.events.EVENT_OPEN_FIRST && !state.events.EVENT_OPEN_FIRST.fired) {
          state.events.EVENT_OPEN_FIRST.fired = true
          doActions(state.events.EVENT_OPEN_FIRST.actions)
          /*setTimeout(() => {
               doActions(state.events.EVENT_OPEN_FIRST.actions)
          }, 1000)*/


     }
     if (state.events.EVENT_OPEN) {
          doActions(state.events.EVENT_OPEN.actions)
     }
}

function doEnd360Event(event) {
     // console.log('end 360 ', event);
     doActions(event.actions)
}
