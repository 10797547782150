import {sceneStoreApi} from "../store/scenes/sceneStore";
import {useNavigate} from "react-router-dom";
import {hotspotStoreApi} from "../store/hotspots/hotspotStore";
import {iconStoreApi} from "../store/icons/iconsStore";
import {audioStoreApi} from "../store/audio/audioStore";
import globalSetStore, {globalSetStoreAPI} from "../store/globalSets/globalSetStore";
import Interweave from "interweave";
import {useEffect, useRef} from "react";
import themeStore from "../store/themes/themeStore";
import "./_pagehome.scss"
import {trackEvent, TrackingLabels} from "../tracking/Tracking";
import siteStore, {STATE_HOME} from "../store/siteStore";
import {debounce} from "debounce";
import {useControl} from "../utils/hooks";
import {preloadStore} from "../sitecomponents/Preloader";
import LucozadeTracking from "../sitecomponents/LucozadeTracking";
import DebugBox from "../experience/debug/DebugBox";
import DebugCC from "./DebugCC";

/**
 * This is the home page
 * @returns {JSX.Element}
 * @constructor
 */
function PageHome() {

    const navigate = useNavigate();
    const globalSets = globalSetStore(state => state.globalSets)
    const theme = themeStore(state => state.theme)
    const loading = siteStore(state => state.loading)
    const landingPagePreload = preloadStore(state => state.landingPagePreload)
    const bgRef = useRef()

    function Control() {
        const keyPress = useControl(STATE_HOME)
        useEffect(()=> {
            if (!keyPress) return
            // console.log(`home`, keyPress.key)
            switch (keyPress.key) {
                case "A":
                    onStart()
                    break
                default:
            }
        }, [keyPress])
        return null
    }

    useEffect(() => {
        // console.log('landingPagePreloaded');
        if (!landingPagePreload) return
        bgRef.current.style.backgroundImage = `url(${landingPagePreload})`
    }, [landingPagePreload])

    function onClick() {
        // const v = videoActions.getVideoByID('613');
        const v = hotspotStoreApi.getState().actions.getHotspotByID('1956');
        console.log('hotspot', v);
    }

    function onClick2() {
        // const v = videoActions.getVideoByID('613');
        const v = sceneStoreApi.getState().actions.getScene('2596');
        console.log('scene', v);
    }

    function onClick3() {
        const v = iconStoreApi.getState().actions.getIconByID('2310')
        console.log('icon', v);
    }

    function onClick4() {
        const v = audioStoreApi.getState().actions.getAudioByID('3292')
        console.log('audio', v);
    }

    function hackPlay(videoElement, start) {
        videoElement.muted = true
        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                console.log('playing ok', videoElement.src);
                videoElement.pause();
                if (start) {
                    navigate('/experience')
                }
            })
                .catch(error => {
                    console.log('error', error);
                });
        }
    }
    function onStart() {
        if (loading) return
        // navigate('/experience')
        hackPlay(document.getElementById("newvid1"), true)

        // tracking
            trackEvent(
                {
                    name: TrackingLabels.names.BUTTON_CLICK,
                    category: TrackingLabels.categories.LANDING_PAGE,
                    label: 'enter'
                }
            )
        // end tracking
    }

    return (
        <div className="page">
            {
                theme.logo ?
                    <div className="ui__icon ui__logo">
                        <img src={theme.logo}/>
                    </div>
                    : null
            }
                {
                    globalSets ?
                        <>
                            <div className="page__home">
                                <Interweave content={globalSets.landingPageCopy}/>
                                    <div className="startbutton">
                                        <div className="cp-infx-button" onClick={debounce(onStart, 2000, true)}>
                                            <a nohref="" data-allow-cookies="" className="pointer"
                                               title="start">
                                                <span className="button-text">
                                                    {loading ? "loading" : globalSets.landingPageButton}
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                <div className="terms">
                                    <Interweave content={globalSets.landingPageTerms}/>
                                </div>
                            </div>
                            <div className="page__background" ref={bgRef}/>
                        </>
                        : null
                }
            <Control/>
            <LucozadeTracking/>


            {/*<DebugCC/>*/}

        </div>
    )
}

export default PageHome
